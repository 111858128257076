import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store"

const useOpenSearchResults = (threadItem) => {
  const { openedSearchResults } = useUniversalAiSelector()
  const dispatch = useDispatch()

  // true by default
  const opened = openedSearchResults[threadItem.id] !== false

  const open = () => {
    dispatch(actions.openSearchResults(threadItem.id))
  }

  const close = () => {
    dispatch(actions.closeSearchResults(threadItem.id))
  }

  const toggle = () => (opened ? close() : open())

  return { opened, open, close, toggle }
}

export default useOpenSearchResults
