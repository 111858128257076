import { styled } from "@linaria/react"

export const Container = styled.div`
  width: 100%;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  opacity: 0.95;
  border-radius: 0.25rem;

  &.warning {
    color: rgb(132.6, 100.36, 3.64);
    background-color: rgb(255, 242.6, 205.4);
    border-color: rgb(255, 237.64, 185.56);
  }
`
