import { useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import axios from "axios"

import { TRANSCRIPT_STATUSES } from "src/resources/meetingPacks/constants"

import uploadUrlQuery from "./uploadUrl.gql"
import createTranscriptMutation from "./create.gql"

export default function useUpload({ meetingPackId, setStatus }) {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [createTranscript] = useMutation(createTranscriptMutation)

  const setAllFilesAsUploading = (isUploadingStatus) => {
    setSelectedFiles(
      selectedFiles.map((file) => ({ ...file, isUploading: isUploadingStatus }))
    )
  }

  const handleUpload = (audioUploadUrls) => {
    Promise.all(
      selectedFiles.map((selectedFile, index) =>
        axios.put(audioUploadUrls[index].uploadUrl, selectedFile.file)
      )
    )
      .then(() =>
        createTranscript({
          variables: {
            meetingPackId,
            audioFileStorageKeys: audioUploadUrls.map(
              (audioUploadUrl) => audioUploadUrl.storageKey
            )
          }
        })
      )
      .then(({ data: result }) => {
        setAllFilesAsUploading(false)

        if (result.createTranscript.successful) {
          setStatus(TRANSCRIPT_STATUSES.uploaded)
        } else {
          setStatus(TRANSCRIPT_STATUSES.failed)
        }
      })
      .catch(() => {
        setAllFilesAsUploading(false)
        setStatus(TRANSCRIPT_STATUSES.failed)
      })
  }

  const [loadUploadUrl] = useLazyQuery(uploadUrlQuery, {
    onCompleted: (data) => {
      const audioUploadUrls = data?.meetingPackTranscriptUploadUrl || []
      handleUpload(audioUploadUrls)
    }
  })

  const uploadFiles = () => {
    setAllFilesAsUploading(true)
    loadUploadUrl({
      variables: { meetingPackId, count: selectedFiles.length },
      fetchPolicy: "network-only"
    })
  }

  const isUploading = selectedFiles.some((file) => file.isUploading)

  return {
    setSelectedFiles,
    selectedFiles,
    uploadFiles,
    isUploading
  }
}
