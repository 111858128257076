import { useQuery } from "@apollo/client"

import recentItemsQuery from "./recentItemsQuery.gql"

const useRecentItems = () => {
  const { data, loading, refetch } = useQuery(recentItemsQuery, {
    fetchPolicy: "cache-first"
  })

  const recentItems = data?.aiAreaOfFocusRecentItems || []

  return { recentItems, loading, refetch }
}

export default useRecentItems
