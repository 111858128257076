import React, { useRef } from "react"
import { arrayOf, string } from "prop-types"
import { captureMessage } from "@sentry/browser"

import useMediaQuery from "src/hooks/useMediaQuery"
import useCmdKForSearch from "src/hooks/useCmdKForSearch"
import { closeSidebar } from "src/helpers/document"
import { BREAKPOINTS } from "src/styles/sizes"
import useFeatureFlag from "src/hooks/useFeatureFlag"
import { useUniversalAi } from "src/features/UniversalAi"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import useCurrentEntity from "src/hooks/useCurrentEntity"

import IconAI from "./IconAI"
import { NavLink } from "./styles"

const WHERE_ID_TO_FOCUSABLE_TYPE = {
  group: FocusableItemType.Group,
  discussion: FocusableItemType.Discussion,
  meeting: FocusableItemType.MeetingPack,
  jointScheme: FocusableItemType.JointScheme,
  privateScheme: FocusableItemType.PrivateScheme
}

const whereIdToFocusableItem = (whereId) => {
  if (!whereId) return {}

  const [entityType, entityId] = whereId.split("/")

  const focusableType = WHERE_ID_TO_FOCUSABLE_TYPE[entityType]

  if (!focusableType) {
    console.error(`Not supported whereId type: ${entityType}`)
    captureMessage(`Not supported whereId type: ${entityType}`)
    return null
  }

  return { id: entityId, type: focusableType }
}

const SearchNavIcon = ({ whereIds }) => {
  const { flagValue: pineconeSearchEnabled } = useFeatureFlag("pinecone-search-enabled")
  const { queryInSidebar } = useUniversalAi()
  const { currentEntity } = useCurrentEntity()

  const isSmallScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)
  const navLinkRef = useRef()

  const findCurrentFocusableItem = () => {
    if (currentEntity?.id) return currentEntity

    const whereId = (whereIds || [])[0]
    return whereIdToFocusableItem(whereId)
  }

  const handleOpen = () => {
    if (isSmallScreen) closeSidebar()

    queryInSidebar(findCurrentFocusableItem())
  }

  useCmdKForSearch(() => navLinkRef.current.click())

  return (
    <NavLink
      className="header-nav-item search-link"
      data-testid="search-navbar-link"
      onClick={pineconeSearchEnabled ? handleOpen : undefined}
      ref={navLinkRef}
    >
      {pineconeSearchEnabled && <IconAI />}
    </NavLink>
  )
}

SearchNavIcon.propTypes = {
  whereIds: arrayOf(string)
}

export default SearchNavIcon
