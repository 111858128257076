import { styled } from "@linaria/react"

import UnstyledButton from "src/styles/components/Button/Unstyled"
import { BREAKPOINTS } from "src/styles/sizes"

const HEADER_SIZE = "49px"

export const SidebarHeadContainer = styled.div`
  display: flex;
  flex: 0 0 ${HEADER_SIZE};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0.5rem 12px;

  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    display: none;
  }

  a,
  svg {
    display: block;
  }

  svg {
    height: 28px;
    width: 98px;
  }
`

export const SidebarToggleButton = styled(UnstyledButton)`
  .icon-arrow-left-circled {
    transition: all 300ms;
  }

  &:hover {
    .icon-arrow-left-circled {
      margin-right: 8px;
    }
  }
`
