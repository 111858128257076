import { useDispatch } from "react-redux"

import useUniversalAiSelector from "src/features/UniversalAi/store/useUniversalAiSelector"
import { actions } from "src/features/UniversalAi/store/slice"

const useStoredQuery = () => {
  const { query } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const setQuery = (newQuery) => {
    dispatch(actions.setQuery(newQuery))
  }

  return { query, setQuery }
}

export default useStoredQuery
