import { isIphone, isPWA } from "src/helpers/browser"

document.addEventListener("turbo:load", () => {
  const body = document.querySelector("body")

  if (isPWA()) {
    body.classList.add("is-pwa")
  }

  if (isIphone()) {
    body.classList.add("is-iphone")
  }
})

const scrollPositions = {}

document.addEventListener("turbo:before-render", () => {
  document.querySelectorAll("[data-turbo-preserve-scroll]").forEach((element) => {
    scrollPositions[element.id] = element.scrollTop
  })
})

document.addEventListener("turbo:render", () => {
  document.querySelectorAll("[data-turbo-preserve-scroll]").forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.scrollTop = scrollPositions[element.id]
  })
})
