import { useEffect, useState } from "react"

import { copyHTMLText } from "src/helpers/copyText"

const DEFAULT_DELAY = 1500

const useCopy = ({ parent, content, delay = DEFAULT_DELAY }) => {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (!copied) return

    const timeoutId = setTimeout(() => setCopied(false), delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [copied])

  const copy = () => {
    copyHTMLText(parent, content, { ignoredTags: ["sup"] })
    setCopied(true)
  }

  return { copied, copy }
}

export default useCopy
