import { styled } from "@linaria/react"

import { BASE_COLORS, BORDER_COLORS, BACKGROUND_COLORS } from "src/styles/colors"
import { BREAKPOINTS } from "src/styles/sizes"

export const BodyContainer = styled.div`
  padding: 20px;

  @media (max-width: calc(${BREAKPOINTS.xSmall} - 1px)) {
    padding: 0px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .btn {
    width: 30%;
    min-width: fit-content;
  }

  .btn-primary {
    background-color: ${BASE_COLORS.lightBlue};
    border-color: ${BORDER_COLORS.button};
    &:active,
    &:focus,
    &:hover {
      background-color: ${BORDER_COLORS.button};
      border-color: ${BORDER_COLORS.button};
    }
  }

  .btn-outline-primary {
    color: ${BASE_COLORS.lightBlue};
    border-color: ${BASE_COLORS.lightBlue};

    &:active,
    &:focus,
    &:hover {
      background-color: ${BACKGROUND_COLORS.focused};
    }
  }
`
