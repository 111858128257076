import { useQuery } from "@apollo/client"

import useUniversalAi from "src/features/UniversalAi/useUniversalAi"
import { useUniversalAiSelector } from "src/features/UniversalAi/store"

import focusedItemQuery from "./focusedItemQuery.gql"

const useFocusedItem = () => {
  const { focusedItem } = useUniversalAiSelector()
  const { setFocusedItem } = useUniversalAi()

  const { id, type, title } = focusedItem ?? {}
  const isLoaded = !!id && !!type && !!title
  const skip = isLoaded || !id || !type

  useQuery(focusedItemQuery, {
    skip,
    variables: { id, type },
    fetchPolicy: "cache-first",
    onCompleted: (data) => setFocusedItem(data?.aiAreaOfFocusFocusedItem)
  })

  return {
    focusedItem: isLoaded ? focusedItem : null,
    setFocusedItem,
    loading: !isLoaded && !skip
  }
}

export default useFocusedItem
