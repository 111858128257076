import React from "react"
import { number, bool, func, shape } from "prop-types"
import { Modal } from "react-bootstrap"

import { close as closeSignedDocument } from "src/api/signedDocuments"

import ModalBody from "./Body"

const turboRefresh = (messageId) => {
  const url = new URL(window.location)
  const searchParams = new URLSearchParams(url.search)
  searchParams.set("message_id", messageId)
  url.search = searchParams
  window.Turbo.visit(url)
}

const CloseActionModal = ({ show, close, closeAllModals, document }) => {
  const onCancel = () => {
    close()
  }
  const onConfirm = () => {
    closeSignedDocument({ documentId: document.id })
    closeAllModals()
    turboRefresh(document.messageId)
  }

  return (
    <Modal show={show} keyboard onHide={onCancel}>
      <Modal.Header closeButton onHide={closeAllModals}>
        <Modal.Title as="h5">Close document immediately</Modal.Title>
      </Modal.Header>

      <ModalBody onConfirm={onConfirm} onCancel={onCancel} />
    </Modal>
  )
}

CloseActionModal.propTypes = {
  show: bool.isRequired,
  close: func.isRequired,
  closeAllModals: func.isRequired,
  document: shape({
    id: number.isRequired,
    messageId: number.isRequired
  }).isRequired
}

export default CloseActionModal
