import { useDispatch, useSelector } from "react-redux"

import { actions } from "./store/slice"

const useDocumentPreview = () => {
  const dispatch = useDispatch()

  const {
    opened,
    document: currentDocument,
    collection: currentCollection
  } = useSelector((state) => state.documentPreview)

  const open = ({ document, collection = [] }) => {
    dispatch(actions.open({ document, collection }))
  }

  const close = () => dispatch(actions.close())

  return { open, close, opened, document: currentDocument, collection: currentCollection }
}

export default useDocumentPreview
