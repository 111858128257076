import { bool, number, string } from "prop-types"
import React from "react"
import { createPortal } from "react-dom"

import DocumentPreview, { Modes } from "src/features/DocumentPreview"
import { getBaseUrl } from "src/helpers/document"
import { useUniversalAi } from "src/features/UniversalAi"
import { OBJECT_TYPES, FocusableItemType } from "src/features/UniversalAi/constants"
import { buildOpenaiButtons } from "src/helpers/box"
import { splitFilename } from "src/helpers/string"
import useFeatureFlag from "src/hooks/useFeatureFlag"

import { CopyLink } from "./styles"

const FilePreview = ({ accountId, groupId, folderId, sidebarEnabled = false }) => {
  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()
  const { flagValue: aiFeaturesEnabled } = useFeatureFlag("ai-features-enabled", {
    context: { accountId }
  })

  const toolbarAiButtons = (file) => {
    if (!aiFeaturesEnabled || !file.contentCloudFile?.contentPresent) return []

    const { summariseButton, askQuestionButton } = buildOpenaiButtons()
    const title = splitFilename(file.filename).name
    const contentCloudFileId = file.contentCloudFile.id

    summariseButton.element.addEventListener("click", () =>
      summariseAsTextInSidebar({
        objectId: contentCloudFileId,
        objectType: OBJECT_TYPES.contentCloudFile,
        id: contentCloudFileId,
        type: FocusableItemType.ContentCloudFile,
        title
      })
    )

    askQuestionButton.element.addEventListener("click", () =>
      queryInSidebar({
        objectId: contentCloudFileId,
        objectType: OBJECT_TYPES.contentCloudFile,
        objectTitle: title,
        id: contentCloudFileId,
        type: FocusableItemType.ContentCloudFile,
        title
      })
    )

    return [summariseButton, askQuestionButton]
  }

  const toolbarCopyLinkButton = (file) => {
    const baseURL = getBaseUrl()
    const fileUrl = `${baseURL}/groups/${groupId}/documents?file_id=${file.boxFileId}&folder_id=${folderId}`

    const element = <CopyLink url={fileUrl} labelText={null} />

    return { element, align: "right" }
  }

  const toolbarButtonsFactory = (file) => {
    return [...toolbarAiButtons(file), toolbarCopyLinkButton(file)]
  }

  return createPortal(
    <DocumentPreview
      mode={Modes.Embedded}
      sidebarEnabled={sidebarEnabled}
      toolbarButtonsFactory={toolbarButtonsFactory}
    />,
    document.querySelector("[data-behavior=document-preview-container]")
  )
}

FilePreview.propTypes = {
  accountId: number.isRequired,
  groupId: number.isRequired,
  folderId: string.isRequired,
  sidebarEnabled: bool
}

export default FilePreview
