import { styled } from "@linaria/react"

export const BASE_TEXT_COLOR = "#4f4f58"
export const TRANSITION_PROPERTIES = "0.25s ease-out"

export const Actions = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 12px;
`
