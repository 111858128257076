import React from "react"

import Logo from "./Logo"
import Toggle from "./Toggle"
import { SidebarHeadContainer } from "./styles"

const SidebarHead = () => {
  return (
    <SidebarHeadContainer className="sidebar-head">
      <a href="/" className="logo">
        <Logo />
      </a>
      <Toggle />
    </SidebarHeadContainer>
  )
}

SidebarHead.propTypes = {}

export default SidebarHead
