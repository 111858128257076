import { useDispatch } from "react-redux"

import useUniversalAiSelector from "src/features/UniversalAi/store/useUniversalAiSelector"
import { actions } from "src/features/UniversalAi/store/slice"

const useCommand = () => {
  const { command } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const setCommand = (newCommand) => {
    dispatch(actions.setCommand(newCommand))
  }

  return { command, setCommand }
}

export default useCommand
