import React from "react"
import { Modal, Button } from "react-bootstrap"
import { func } from "prop-types"

import { BodyContainer, ButtonContainer } from "./styles"

const Body = ({ onConfirm, onCancel }) => {
  return (
    <>
      <Modal.Body>
        <BodyContainer data-testid="confirmation-text">
          <p>
            This document will be{" "}
            <strong>immediately closed to further Signatures</strong>.
          </p>
          <p className="text-danger">
            <strong>This cannot be undone. Are you sure you wish to continue?</strong>
          </p>
        </BodyContainer>
      </Modal.Body>

      <Modal.Footer>
        <ButtonContainer>
          <Button variant="primary" size="lg" onClick={onConfirm}>
            Confirm
          </Button>
          <Button variant="outline-primary" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonContainer>
      </Modal.Footer>
    </>
  )
}

Body.propTypes = {
  onConfirm: func.isRequired,
  onCancel: func.isRequired
}

export default Body
