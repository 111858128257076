import { styled } from "@linaria/react"

export const Container = styled.div`
  font-size: 11px;
  color: #000000;
  line-height: 16px;
  font-weight: 400;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  & .highlight {
    background-color: #e5d1f5;
  }
`
