// eslint-disable-next-line import/no-unresolved
import queryIcon from "images/ai/query.svg?raw"
// eslint-disable-next-line import/no-unresolved
import summariseIcon from "images/ai/summarise.svg?raw"

const buildAiButtons = ({
  documentContentExtracted,
  summariseDocumentHandler,
  queryDocumentHandler
}) => {
  if (
    !window.Knowa?.featureFlags?.aiFeaturesEnabled ||
    !documentContentExtracted ||
    !navigator.onLine
  ) {
    return []
  }

  return [
    {
      type: "custom",
      className: "custom-button ai-button",
      id: "ai-button-summarise",
      icon: summariseIcon,
      title: "Summarise document",
      dropdownGroup: "ai-dropdown",
      disabledOffline: true,
      onPress() {
        summariseDocumentHandler()
      }
    },
    {
      type: "custom",
      className: "custom-button ai-button",
      id: "ai-button-custom-prompt-document",
      icon: queryIcon,
      title: "Query document",
      dropdownGroup: "ai-dropdown",
      disabledOffline: true,
      onPress() {
        queryDocumentHandler()
      }
    }
  ]
}

export default buildAiButtons
