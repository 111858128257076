import React from "react"
import * as Sentry from "@sentry/react"

const DefaultFallback = () => <p className="p-1">An error has occurred</p>

export const { ErrorBoundary } = Sentry

export const withErrorBoundary =
  (Component, fallback = <DefaultFallback />) =>
  (props) => (
    <ErrorBoundary fallback={fallback}>
      <Component {...props} />
    </ErrorBoundary>
  )
