import { string } from "prop-types"
import React from "react"

import { Container } from "./styles"

const Excerpt = ({ text }) => {
  if (!text) return null

  return <Container dangerouslySetInnerHTML={{ __html: text }} />
}

Excerpt.propTypes = {
  text: string
}

export default Excerpt
