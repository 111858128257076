import { useQuery } from "@apollo/client"

import { SUGGESTIONS } from "src/features/Search/constants"

import searchSuggestionsQuery from "./searchSuggestions.gql"
import searchFoldersSuggestionsQuery from "./searchFoldersSuggestions.gql"
import { MINIMUM_QUERY_LENGTH } from "./constants"

const sortedSuggestionTypes = [
  SUGGESTIONS.meeting,
  SUGGESTIONS.privateScheme,
  SUGGESTIONS.jointScheme,
  SUGGESTIONS.group,
  SUGGESTIONS.discussion,
  SUGGESTIONS.app,
  SUGGESTIONS.folder
]

const mergeSuggestionsData = (data, foldersData) => [
  ...(data?.searchSuggestions || []),
  ...(foldersData?.searchFoldersSuggestions || [])
]

const sortSuggestions = (suggestion1, suggestion2) =>
  sortedSuggestionTypes.indexOf(suggestion1.type) -
  sortedSuggestionTypes.indexOf(suggestion2.type)

const useSuggestions = (searchText) => {
  const { data, previousData } = useQuery(searchSuggestionsQuery, {
    variables: { query: searchText },
    skip: (searchText?.length || 0) < MINIMUM_QUERY_LENGTH
  })
  const { data: foldersData, previousData: previousFoldersData } = useQuery(
    searchFoldersSuggestionsQuery,
    {
      variables: { query: searchText },
      skip: (searchText?.length || 0) < MINIMUM_QUERY_LENGTH
    }
  )

  const allSuggestions = mergeSuggestionsData(
    data || previousData,
    foldersData || previousFoldersData
  ).sort(sortSuggestions)

  return { suggestions: allSuggestions }
}

export default useSuggestions
