import React, { useRef } from "react"
import { useFloating, autoUpdate, flip, shift, useMergeRefs } from "@floating-ui/react"

import { ThreadItem as ThreadItemType } from "src/features/UniversalAi/Sidebar/propTypes"
import { Status } from "src/features/UniversalAi/constants"
import useMobileScreen from "src/hooks/useMobileScreen"
import useUniversalAi from "src/features/UniversalAi/useUniversalAi"

import useCopy from "./useCopy"
import useOpenSources from "./useOpenSources"
import Sources, { LinkTitleMode } from "./Sources"
import SourcePreview from "./SourcePreview"
import { Container, CopyIcon, CopyResponseButton, CopyResponseContainer } from "./styles"
import useSourceInteractions from "./useSourceInteractions"

const Answer = ({ threadItem }) => {
  const { answer, focusable, status, sources = [] } = threadItem
  const inProgress = status === Status.InProgress

  const isMobile = useMobileScreen()
  const { close: closeSidebar } = useUniversalAi()
  const { opened: sourcesOpened, toggle: toggleSources } = useOpenSources(threadItem)

  const answerRef = useRef()
  const { copied, copy } = useCopy({ parent: answerRef.current, content: answer })

  const sourcePreviewRef = useRef()
  const { source: activeSource, htmlElement } = useSourceInteractions({
    sources,
    sourcePreviewRef,
    containerRef: answerRef,
    skip: inProgress
  })

  const { refs, floatingStyles } = useFloating({
    elements: { reference: htmlElement },
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift()]
  })

  const allSourcePreviewRefs = useMergeRefs([sourcePreviewRef, refs.setFloating])

  const navigateToSource = () => {
    if (!activeSource || activeSource.sourceDeleted) return

    window.Turbo.visit(activeSource.link)
    if (isMobile) closeSidebar()
  }

  const showSourcePreview = !!activeSource
  const showSources = !inProgress && sources?.length > 0

  const areSourcesLocal = sources.every((source) => {
    return (
      source.sourceType === focusable.type && String(source.sourceId) === focusable.id
    )
  })

  const linkTitleMode = areSourcesLocal ? LinkTitleMode.PageNumber : LinkTitleMode.Title

  return (
    <>
      <Container
        ref={answerRef}
        dangerouslySetInnerHTML={{ __html: answer }}
        className={inProgress && "in-progress"}
      />

      {showSources && (
        <Sources
          sources={sources}
          opened={sourcesOpened}
          toggle={toggleSources}
          linkTitleMode={linkTitleMode}
        />
      )}

      {!!answer && !inProgress && (
        <CopyResponseContainer>
          <CopyResponseButton onClick={copy}>
            <CopyIcon type="copy" />
            {copied ? "Copied!" : "Copy response"}
          </CopyResponseButton>
        </CopyResponseContainer>
      )}

      {showSourcePreview && (
        <SourcePreview
          ref={allSourcePreviewRefs}
          style={floatingStyles}
          source={activeSource}
          onNavigate={navigateToSource}
        />
      )}
    </>
  )
}

Answer.propTypes = {
  threadItem: ThreadItemType.isRequired
}

export default Answer
