import React from "react"
import { styled } from "@linaria/react"
import { string } from "prop-types"

import { TEXT_COLORS } from "src/styles/colors"
import { formatDate } from "src/helpers/datetime"

const ClosedMessage = ({ closedAt, className }) => {
  return <span className={className}>Closed {formatDate(closedAt)}</span>
}

ClosedMessage.propTypes = {
  closedAt: string.isRequired
}

export default styled(ClosedMessage)`
  color: ${TEXT_COLORS.muted};
`
