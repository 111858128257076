import React, { useCallback, useEffect } from "react"

import EmptyContent from "src/styles/components/EmptyContent"
import { KEY_CODES } from "src/constants"
import useCurrentEntity from "src/hooks/useCurrentEntity"

import Document from "./Document"
import AgendaItemSummary from "./AgendaItemSummary"
import NavigationIcon from "./NavigationIcon"
import { ContentContainer, EmptyContentContainer } from "./styles"
import useContentState from "./useContentState"
import useNavigation from "./useNavigation"

const Content = () => {
  const {
    activeItemId,
    activeDocumentId,
    highlighted,
    isPrevButtonDisabled,
    isNextButtonDisabled
  } = useContentState()
  const { goBack, goForward } = useNavigation()
  const { setCurrentEntity, clearCurrentEntity } = useCurrentEntity()

  const activeId = activeDocumentId || activeItemId
  const showDocument = !!activeDocumentId
  const setActiveItemBefore = useCallback(() => goBack(), [activeId])
  const setActiveItemNext = useCallback(() => goForward(), [activeId])

  const handleKeyDown = useCallback((event) => {
    // Bug fix: when Universal AI sidebar is opened in "Query" mode and
    // user navigates with arrow keys in the prompt box then
    // user is also navigated to the next or previous meeting pack item.
    if (event.srcElement !== document.body) return

    const { keyCode } = event

    switch (keyCode) {
      case KEY_CODES.leftArrow:
      case KEY_CODES.upArrow:
        goBack()
        break
      case KEY_CODES.rightArrow:
      case KEY_CODES.downArrow:
        goForward()
        break
      default:
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)

    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [])

  useEffect(() => {
    if (!activeDocumentId) return
    setCurrentEntity({ id: activeDocumentId, type: "meeting_packs_agenda_item_document" })

    return () => clearCurrentEntity()
  }, [activeDocumentId])

  return activeId ? (
    <ContentContainer className="view-content-container">
      <NavigationIcon
        onClick={setActiveItemBefore}
        type="navigate-before"
        size="large"
        disabled={isPrevButtonDisabled}
      />
      {showDocument ? (
        <Document highlighted={highlighted} />
      ) : (
        <AgendaItemSummary highlighted={highlighted} />
      )}
      <NavigationIcon
        onClick={setActiveItemNext}
        type="navigate-next"
        size="large"
        disabled={isNextButtonDisabled}
      />
    </ContentContainer>
  ) : (
    <EmptyContentContainer>
      <EmptyContent>You have not been added to any items.</EmptyContent>
    </EmptyContentContainer>
  )
}

export default Content
