import React, { useState } from "react"
import { arrayOf, number, oneOf, shape, string } from "prop-types"
import { styled } from "@linaria/react"

import { BREAKPOINTS } from "src/styles/sizes"
import useIsOpened from "src/hooks/useIsOpened"

import { TABS } from "./constants"
import DocumentList from "./Documents"
import LinkList from "./Links"
import EmailsList from "./Emails"
import DocumentFilter from "./Filters/DocumentFilter"
import LinkFilter from "./Filters/LinkFilter"
import EmailsFilter from "./Filters/EmailsFilter"

const Content = ({ discussion, participants, kind, className }) => {
  const [filtersDocs, setFiltersDocs] = useState({})
  const [filtersLinks, setFiltersLinks] = useState({})
  const [filtersForwardingEmails, setFiltersForwardingEmails] = useState({})
  const { isOpened: filtersDocsOpened, toggle: toggleFiltersDocsOpened } = useIsOpened()
  const { isOpened: filtersLinksOpened, toggle: toggleFiltersLinksOpened } = useIsOpened()
  const {
    isOpened: filtersForwardingEmailsOpened,
    toggle: toggleFiltersForwardingEmailsOpened
  } = useIsOpened()

  return (
    <div className={className}>
      <DocumentFilter
        setFilters={setFiltersDocs}
        participants={participants}
        opened={filtersDocsOpened}
        kind={kind}
        toggle={toggleFiltersDocsOpened}
        visible={kind === TABS.DOCS}
      />
      <DocumentList
        discussionId={discussion.id}
        filters={filtersDocs}
        filtersOpened={filtersDocsOpened}
        visible={kind === TABS.DOCS}
      />

      <LinkFilter
        setFilters={setFiltersLinks}
        participants={participants}
        kind={kind}
        opened={filtersLinksOpened}
        toggle={toggleFiltersLinksOpened}
        visible={kind === TABS.LINKS}
      />
      <LinkList
        discussionId={discussion.id}
        filters={filtersLinks}
        filtersOpened={filtersLinksOpened}
        visible={kind === TABS.LINKS}
        kind={TABS.LINKS}
      />

      <EmailsFilter
        setFilters={setFiltersForwardingEmails}
        participants={participants}
        kind={kind}
        opened={filtersForwardingEmailsOpened}
        toggle={toggleFiltersForwardingEmailsOpened}
        visible={kind === TABS.EMAILS}
      />
      <EmailsList
        discussionId={discussion.id}
        filters={filtersForwardingEmails}
        filtersOpened={filtersForwardingEmailsOpened}
        visible={kind === TABS.EMAILS}
        kind={TABS.EMAILS}
      />
    </div>
  )
}

Content.propTypes = {
  className: string.isRequired,
  kind: oneOf([...Object.values(TABS)]).isRequired,
  participants: arrayOf(
    shape({
      id: number.isRequired,
      firstName: string.isRequired,
      lastName: string.isRequired
    })
  ),
  discussion: shape({
    id: number.isRequired
  })
}

export default styled(Content)`
  font-size: 0.8em;

  @media (max-width: ${BREAKPOINTS.small}) {
    height: calc(100% - 49px);
    overflow-y: scroll;
  }

  .icon-rotated {
    top: 1px;
    transform: rotate(180deg);
  }
`
