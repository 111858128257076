import { useSelector } from "react-redux"

import { getViewContentState } from "src/features/meetingPacks/MeetingPack/selectors"

const useContentState = () => {
  const contentState = useSelector((state) => getViewContentState(state))

  return contentState
}

export default useContentState
