import React from "react"
import { arrayOf } from "prop-types"

import { ThreadItem } from "../propTypes"

import GroupedThreadItems from "./GroupedThreadItems"
import { Banner, ClockIcon } from "./styles"

const groupThreadItems = (threadItems) => {
  if (threadItems.length === 0) return []

  const groupedThreadItems = [[threadItems[0].focusable, [threadItems[0]]]]
  if (threadItems.length === 1) return groupedThreadItems

  for (let i = 1; i < threadItems.length; i += 1) {
    const previousFocusable = threadItems[i - 1].focusable
    const currentThreadItem = threadItems[i]
    const currentFocusable = currentThreadItem.focusable

    if (
      previousFocusable.id === currentFocusable.id &&
      previousFocusable.type === currentFocusable.type
    ) {
      const group = groupedThreadItems.find(
        ([focusable]) =>
          focusable.id === currentFocusable.id && focusable.type === currentFocusable.type
      )

      group[1].push(currentThreadItem)
    } else {
      groupedThreadItems.push([currentFocusable, [currentThreadItem]])
    }
  }

  return groupedThreadItems
}

const ThreadItems = ({ threadItems }) => {
  const groupedThreadItems = groupThreadItems(threadItems)

  return (
    <div>
      <Banner>
        <ClockIcon type="clock" />
        <span>Previous queries</span>
      </Banner>

      {groupedThreadItems.map(([focusableItem, threadItemsInGroup]) => (
        <GroupedThreadItems
          key={`${focusableItem.type}-${focusableItem.id}-${threadItemsInGroup[0].id}`}
          focusableItem={focusableItem}
          threadItems={threadItemsInGroup}
        />
      ))}
    </div>
  )
}

ThreadItems.propTypes = {
  threadItems: arrayOf(ThreadItem).isRequired
}

export default ThreadItems
