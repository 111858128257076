import React from "react"
import { arrayOf } from "prop-types"

import { FocusableItem, ThreadItem } from "src/features/UniversalAi/Sidebar/propTypes"

import { buildTitleWithDeletedLabel } from "../../helpers"

import {
  Container,
  Header,
  HeaderLine,
  HeaderTitle,
  FocusableItemIcon,
  HeaderLabel
} from "./styles"
import ExpandableThreadItem from "./ExpandableThreadItem"

const GroupedThreadItems = ({ focusableItem, threadItems }) => {
  return (
    <Container>
      <Header>
        <HeaderLine />
        <HeaderTitle>
          <FocusableItemIcon itemType={focusableItem.type} />
          <HeaderLabel>
            {buildTitleWithDeletedLabel(focusableItem.title, focusableItem.deleted)}
          </HeaderLabel>
        </HeaderTitle>
      </Header>

      {threadItems.map((threadItem) => (
        <ExpandableThreadItem key={threadItem.id} threadItem={threadItem} />
      ))}
    </Container>
  )
}

GroupedThreadItems.propTypes = {
  focusableItem: FocusableItem.isRequired,
  threadItems: arrayOf(ThreadItem)
}

export default GroupedThreadItems
