import { useQuery } from "@apollo/client"
import { orderBy, take } from "lodash"

import { buildSearchQueryPath } from "src/helpers/url"
import { SUGGESTIONS } from "src/features/Search/constants"
import useListKeyboardNavigation from "src/hooks/forms/useListKeyboardNavigation"

import recentSearchesQuery from "./recentSearches.gql"
import recentJumpTosQuery from "./recentJumpTos.gql"

const recentSearchesCount = 7

const normalizeRecentSearch = (search) => ({
  ...search,
  type: SUGGESTIONS.search,
  title: search.query,
  path: buildSearchQueryPath(search)
})

const getRecentSearches = (recentSearchesData) => {
  if (!recentSearchesData?.recentSearches) return []

  return recentSearchesData.recentSearches.map(normalizeRecentSearch)
}

const mergeRecentData = (recentSearchesData, recentJumpTosData) => [
  ...getRecentSearches(recentSearchesData),
  ...(recentJumpTosData?.recentJumpTos || [])
]

const useSuggestions = (searchText) => {
  const { data: recentSearchesData } = useQuery(recentSearchesQuery, {
    skip: (searchText?.length || 0) > 0
  })
  const { data: recentJumpTosData } = useQuery(recentJumpTosQuery, {
    skip: (searchText?.length || 0) > 0
  })

  const allSuggestions = take(
    orderBy(
      mergeRecentData(recentSearchesData, recentJumpTosData),
      ["updatedAt"],
      ["desc"]
    ),
    recentSearchesCount
  )

  const { itemsWithActiveFlag, activeItem, handleKeyboardNavigation } =
    useListKeyboardNavigation({
      items: allSuggestions,
      firstItemActive: true
    })

  return {
    isSearchActive: !activeItem,
    activeSuggestion: activeItem,
    handleKeyboardNavigation,
    suggestions: Object.entries({ recent: itemsWithActiveFlag })
  }
}

export default useSuggestions
