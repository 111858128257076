import { styled } from "@linaria/react"

export const DraftLabel = styled.div`
  color: #ffa24e;

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`

export const Title = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
