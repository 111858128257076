import React from "react"
import { arrayOf, bool, shape, string } from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"

import {
  SuggestionsContainer,
  SuggestionsTitle
} from "src/features/Sidebar/Suggestions/styles"

import Suggestion from "./Suggestion"

const JumpToPanel = ({ suggestions }) => {
  return (
    <SuggestionsContainer data-testid="jump-to-panel">
      <PerfectScrollbar>
        <SuggestionsTitle>Jump To</SuggestionsTitle>

        {suggestions.map((suggestion) => (
          <Suggestion
            key={suggestion.path}
            type={suggestion.type}
            title={suggestion.title}
            parent={suggestion.parent}
            path={suggestion.path}
            active={suggestion.active}
          />
        ))}
      </PerfectScrollbar>
    </SuggestionsContainer>
  )
}

JumpToPanel.propTypes = {
  suggestions: arrayOf(
    shape({
      path: string.isRequired,
      title: string.isRequired,
      type: string,
      parent: arrayOf(string),
      active: bool
    })
  ).isRequired
}

export default JumpToPanel
