import { styled } from "@linaria/react"

import { FONT_SIZES, SPACES } from "src/styles/sizes"

import { lightenTextMutedColor } from "../styles"

export const FilesListContainer = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
`

export const FileRowContainer = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: ${SPACES.xSmall} 0;
  overflow: hidden;
  border-bottom: 1px dotted ${lightenTextMutedColor};

  &:last-child {
    border-bottom: none;
  }

  &.isDragging {
    opacity: 0;
  }
`

export const FileTitle = styled.div`
  font-size: ${FONT_SIZES.small};
  max-width: 95%;
  word-break: break-word;
`
