import { styled } from "@linaria/react"

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: stretch;

  width: 100%;
`

export const Title = styled.span`
  margin-bottom: 4px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Hint = styled.span`
  font-size: 11px;
  font-weight: 300;
`
