import { styled } from "@linaria/react"

import { Body as BaseBody, Footer as BaseFooter } from "src/styles/components/Modal"

export const Body = styled(BaseBody)`
  overflow: auto;
`

export const Footer = styled(BaseFooter)`
  justify-content: flex-end;
`
