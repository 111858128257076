import React from "react"
import classNames from "classnames"
import { string } from "prop-types"

import { isSidebarVisible, toggleSidebar } from "src/helpers/document"
import Icon from "src/styles/components/Icon"
import useMobileScreen from "src/hooks/useMobileScreen"

import { SidebarToggleButton } from "./styles"

const SidebarToggle = ({ className, badgeClass = "" }) => {
  const isMobile = useMobileScreen()

  return (
    <SidebarToggleButton
      onClick={toggleSidebar}
      className={classNames("saved-offline sidebar-toggle", className, badgeClass, {
        "show-sidebar": isSidebarVisible()
      })}
    >
      <Icon type={isMobile ? "hamburger" : "arrow-right-circled"} />
    </SidebarToggleButton>
  )
}

SidebarToggle.propTypes = {
  badgeClass: string
}

export default SidebarToggle
