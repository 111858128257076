import { styled } from "@linaria/react"

import UnstyledButton from "src/styles/components/Button/Unstyled"
import { contentsSidebarToggleBackground } from "src/styles/colors"
import { BREAKPOINTS } from "src/styles/sizes"

const TEXT_COLOR = "#FFFFFF"
const BACKGROUND_COLOR = "#181023"

export const SidebarToggleButton = styled(UnstyledButton)`
  color: ${TEXT_COLOR};
  display: flex;
  width: 40px;
  height: 38.4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 0 6px 6px 0;
  background: ${BACKGROUND_COLOR};
  z-index: 1;

  .icon-arrow-right-circled {
    transition: all 300ms;
  }

  &:hover {
    .icon-arrow-right-circled {
      margin-left: 8px;
    }
  }

  &:focus {
    color: ${TEXT_COLOR};
  }

  // TODO: remove this when new mobile design will be implemented
  @media (max-width: calc(${BREAKPOINTS.small} - 1px)) {
    border-left: 1px solid ${contentsSidebarToggleBackground};

    display: flex;
    flex: none;
    position: relative;
    width: 49px;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    border-left: 1px solid #cbcdd6;
    color: #9e9eb0;
    order: 2;
    overflow: hidden;
    background: transparent;

    .icon-hamburger {
      transition: opacity 0.5s ease;
    }

    &:focus {
      color: #9e9eb0;
    }

    &:hover {
      .icon-hamburger {
        opacity: 0.5;
      }
    }
  }
`
