import { isIOS } from "./browser"

const defaultCopyInputText = ($input) => {
  $input.select()
  document.execCommand("copy")
  $input.blur()
}

const iosCopyInputText = ($input) => {
  const el = $input[0]
  if (!el) return

  const oldContentEditable = el.contentEditable
  const oldReadOnly = el.readOnly

  el.contentEditable = true
  el.readOnly = false

  const range = document.createRange()
  range.selectNodeContents(el)

  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)

  el.setSelectionRange(0, 999999)

  document.execCommand("copy")

  el.contentEditable = oldContentEditable
  el.readOnly = oldReadOnly

  $input.blur()
}

const copyInputText = ($input) => {
  if (isIOS()) iosCopyInputText($input)
  else defaultCopyInputText($input)
}

const deprecatedCopyPlainText = (string) => {
  const temp = $("<input>")
  $("body").append(temp)
  temp.val(string)
  copyInputText(temp)
  temp.remove()
}

export const copyPlainText = async (string) => {
  // clipboard API is available only in secure context (e.g. HTTPS)
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(string)
  } else {
    deprecatedCopyPlainText(string)
  }
}

export const copyHTMLText = (parentContainer, html, { ignoredTags = [] } = {}) => {
  const container = document.createElement("div")
  container.innerHTML = html

  // make container invisible
  container.style.position = "fixed"
  container.style.pointerEvents = "none"
  container.style.opacity = 0

  // apply to a parent container to keep styles the same
  parentContainer.appendChild(container)

  // remove ignored nodes
  if (ignoredTags.length > 0) {
    container.querySelectorAll(ignoredTags.join(",")).forEach((node) => {
      node.remove()
    })
  }

  // select text
  window.getSelection().removeAllRanges()

  const range = document.createRange()
  range.selectNode(container)
  window.getSelection().addRange(range)

  document.execCommand("copy")

  // we can't remove container right away because copy command needs time to complete
  setTimeout(() => {
    window.getSelection().removeAllRanges()
    parentContainer.removeChild(container)
  }, 1000)
}
