import React from "react"
import { arrayOf, bool, func, number, shape, string } from "prop-types"

import { FilesListContainer } from "./styles"
import SortableFileRow from "./SortableFileRow"
import FileRow from "./FileRow"

const FilesList = ({ files, setFiles, sortingEnabled }) => {
  return (
    <FilesListContainer>
      {files.map(({ file, isUploading }, index) => {
        const key = `${file.name}${file.size}`

        return sortingEnabled ? (
          <SortableFileRow
            key={key}
            file={file}
            isUploading={isUploading}
            index={index}
            setFiles={setFiles}
          />
        ) : (
          <FileRow key={key} file={file} isUploading={isUploading} />
        )
      })}
    </FilesListContainer>
  )
}

FilesList.propTypes = {
  files: arrayOf(
    shape({
      file: shape({
        name: string.isRequired,
        size: number
      }),
      isUploading: bool
    })
  ).isRequired,
  setFiles: func,
  sortingEnabled: bool
}

export default FilesList
