import { styled } from "@linaria/react"

export const LoadingStateContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 11px;
  line-height: 16px;
  color: #3f3f46;
  font-family: "Space Grotesk";
`

export const GeneratingImg = styled.img`
  width: 20px;
  height: 20px;
`
