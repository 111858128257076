import { useCallback, useEffect, useState } from "react"

import { KEY_CODES } from "src/constants"

const addActiveFlagToItem = (activeIndex) => (item, index) => ({
  ...item,
  active: index === activeIndex
})

const useListKeyboardNavigation = ({ items, firstItemActive }) => {
  const firstIndex = firstItemActive ? 0 : -1
  const [activeIndex, setActiveIndex] = useState(firstIndex)

  useEffect(() => {
    setActiveIndex(firstIndex)
  }, [JSON.stringify(items)])

  const handleKeyboardNavigation = useCallback(
    (e) => {
      if (!items.length) return

      if (e.keyCode === KEY_CODES.downArrow) {
        e.preventDefault()
        if (activeIndex === items.length - 1) {
          setActiveIndex(0)
        } else {
          setActiveIndex(activeIndex + 1)
        }
      } else if (e.keyCode === KEY_CODES.upArrow) {
        e.preventDefault()
        if (activeIndex === 0) {
          setActiveIndex(items.length - 1)
        } else {
          setActiveIndex(activeIndex - 1)
        }
      }
    },
    [activeIndex, JSON.stringify(items)]
  )

  const itemsWithActiveFlag = items.map(addActiveFlagToItem(activeIndex))
  const activeItem = items[activeIndex]

  return { itemsWithActiveFlag, activeItem, handleKeyboardNavigation }
}

export default useListKeyboardNavigation
