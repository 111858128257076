import React from "react"
import { Dropdown } from "react-bootstrap"
import { number, func, bool } from "prop-types"
import { useMutation } from "@apollo/client"
import { styled } from "@linaria/react"
import classNames from "classnames"

import Icon from "src/styles/components/Icon"
import queryIcon from "images/ai/query.svg"
import useConfirmationModal from "src/hooks/useConfirmationModal"
import { showFlashMessage } from "src/helpers/flash"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"

import deleteTranscriptMutation from "./deleteTranscriptMutation.gql"

const DropdownActions = ({
  transcriptId,
  onUndo,
  onRedo,
  undoDisabled,
  redoDisabled,
  className
}) => {
  const { queryInSidebar } = useUniversalAi()
  const queryTranscript = () =>
    queryInSidebar({
      id: transcriptId,
      type: FocusableItemType.Transcript
    })

  const [deleteTranscript, { loading: deleteTranscriptLoading }] = useMutation(
    deleteTranscriptMutation
  )

  const handleDeleteTranscript = () => {
    deleteTranscript({ variables: { id: transcriptId } }).then(({ data = {} }) => {
      if (data.deleteTranscript?.successful) {
        window.location.reload()
        showFlashMessage("success", "Transcript was deleted.")
      }
    })
  }

  const { modal: deleteTranscriptModal, open: openDeleteTranscriptModal } =
    useConfirmationModal({
      title: "Confirmation",
      message: "Are you sure you want to delete this transcript?",
      onConfirm: handleDeleteTranscript,
      isConfirming: deleteTranscriptLoading
    })

  return (
    <>
      {deleteTranscriptModal}

      <Dropdown className={classNames("dropdown-menu-right", className)}>
        <Dropdown.Toggle className="subheader-control dropdown-toggle button is-dim">
          <span className="icon-gear" />
          <span className="control-text">Transcript Options</span>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          <Dropdown.Item onClick={onUndo} disabled={undoDisabled}>
            <div className="dropdown-icon">
              <Icon type="reply" />
            </div>
            Undo
          </Dropdown.Item>
          <Dropdown.Item onClick={onRedo} disabled={redoDisabled}>
            <div className="dropdown-icon redo-icon">
              <Icon type="reply" />
            </div>
            Redo
          </Dropdown.Item>
          <Dropdown.Item href={`/transcripts/${transcriptId}/export`} target="_blank">
            <div className="dropdown-icon">
              <Icon type="upload" />
            </div>
            Export Transcript
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={queryTranscript}>
            <div className="dropdown-icon">
              <img src={queryIcon} alt="Query Transcript" />
            </div>
            Query Transcript
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="text-red text-nowrap"
            onClick={openDeleteTranscriptModal}
            variant="danger"
          >
            <div className="dropdown-icon">
              <Icon type="cancel-circled" />
            </div>
            Delete Transcript
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

DropdownActions.propTypes = {
  transcriptId: number.isRequired,
  onUndo: func.isRequired,
  onRedo: func.isRequired,
  undoDisabled: bool.isRequired,
  redoDisabled: bool.isRequired
}

export default styled(DropdownActions)`
  &:not(.show) .subheader-control.button:focus {
    color: #4f4f57 !important;
    background-color: transparent !important;
  }

  .redo-icon .icon-reply {
    transform: rotateY(180deg);
  }
`
