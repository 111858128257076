import React, { useEffect, useRef } from "react"
import { number, string } from "prop-types"
import * as Sentry from "@sentry/browser"

import MeetingPackStore from "src/features/offline/helpers/MeetingPackStore"
import { bodyData } from "src/helpers/document"
import { handlePSPDFKitInitError } from "src/helpers/pspdfkit"

import { DocumentViewerContainer } from "./styles"

const DocumentViewer = ({ documentId, pspdfkitId }) => {
  const containerRef = useRef(null)

  useEffect(() => {
    if (window.PSPDFKit) {
      const meetingPackStore = new MeetingPackStore({ useCache: false })

      meetingPackStore
        .getDocument({ documentId })
        .then((document) => {
          window.PSPDFKit.load({
            licenseKey: window.PSPDFKitKey,
            document,
            toolbarItems: [],
            layers: [],
            enableAutomaticLinkExtraction: false,
            printOptions: {
              mode: window.PSPDFKit.PrintMode.EXPORT_PDF,
              quality: window.PSPDFKit.PrintQuality.HIGH
            },
            container: "#document-preview",
            editableAnnotationTypes: [],
            styleSheets: [bodyData("pspdfkitPreviewPath")],
            instant: false
          }).catch(handlePSPDFKitInitError)
        })
        .catch((e) => {
          console.warn(e)
          Sentry.captureException(e)
        })

      return () => {
        if (document.querySelector("#document-preview"))
          window.PSPDFKit.unload("#document-preview")
      }
    }
  }, [pspdfkitId, !!window.PSPDFKit])

  return <DocumentViewerContainer id="document-preview" ref={containerRef} />
}

DocumentViewer.propTypes = {
  documentId: number.isRequired,
  pspdfkitId: string.isRequired
}

export default DocumentViewer
