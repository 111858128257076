import React from "react"
import { styled } from "@linaria/react"

import knowaQMobile from "images/ai/knowaQMobile.svg"
import knowaQ from "images/ai/knowaQ.svg"
import { BREAKPOINTS } from "src/styles/sizes"
import useMediaQuery from "src/hooks/useMediaQuery"

const IconAI = ({ className }) => {
  const isSmallScreen = useMediaQuery(`(max-width: calc(${BREAKPOINTS.small} - 1px))`)

  return (
    <img
      src={isSmallScreen ? knowaQMobile : knowaQ}
      alt="AI search"
      className={className}
    />
  )
}

export default styled(IconAI)`
  height: 32px;
`
