import React from "react"

import { Source } from "src/features/UniversalAi/Sidebar/propTypes"

import { CreationInfoContainer } from "./styles"

const formatDateTime = (dateTime) => {
  const date = new Date(dateTime)

  const options = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    hour12: true,
    minute: "numeric"
  }

  return date.toLocaleString("en-GB", options)
}

const CreationInfo = ({ source }) => {
  if (!source.createdAt) return null

  const label = source.sourceType === "message" ? "Posted" : "Uploaded"

  return (
    <CreationInfoContainer>
      {label} on {formatDateTime(source.createdAt)}
      {source.author && ` by ${source.author}`}
    </CreationInfoContainer>
  )
}

CreationInfo.propTypes = {
  source: Source.isRequired
}

export default CreationInfo
