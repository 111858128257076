import { useDispatch } from "react-redux"

import useUniversalAiSelector from "src/features/UniversalAi/store/useUniversalAiSelector"
import { actions } from "src/features/UniversalAi/store/slice"
import { generateUniqId } from "src/helpers/string"
import { Status } from "src/features/UniversalAi/constants"

const useCurrentThreadItem = () => {
  const { currentThreadItem } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const setCurrentThreadItem = (threadItem) => {
    dispatch(
      actions.setCurrentThreadItem({
        id: generateUniqId(),
        current: true,
        focusable: null,
        query: null,
        queryType: null,
        answer: null,
        sources: [],
        searchResults: [],
        ...threadItem
      })
    )
  }

  const updateCurrentThreadItem = (threadItem) => {
    dispatch(actions.updateCurrentThreadItem(threadItem))
  }

  const isPending = !currentThreadItem || currentThreadItem.status === Status.Pending
  const isLoading = currentThreadItem?.status === Status.Loading
  const isFinished = currentThreadItem?.status === Status.Finished
  const isInProgress = currentThreadItem?.status === Status.InProgress

  return {
    currentThreadItem,
    isPending,
    isLoading,
    isFinished,
    isInProgress,
    setCurrentThreadItem,
    updateCurrentThreadItem
  }
}

export default useCurrentThreadItem
