import { useQuery } from "@apollo/client"

import query from "./discussionDocuments.gql"

const useDocuments = ({ discussionId, page, filters }) => {
  const { data, loading } = useQuery(query, {
    variables: {
      discussionId,
      page,
      filters
    }
  })

  return { documents: data?.discussionDocuments, loading }
}

export default useDocuments
