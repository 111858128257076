import React, { useState } from "react"
import { bool, number, shape, string } from "prop-types"

import { DrawerList } from "../styles"
import usePagination from "../usePagination"
import LoadMore from "../LoadMore"
import NotFound from "../NotFound"

import EntityRow from "./EntityRow"
import useDocuments from "./useDocuments"

const DocumentList = ({ className, discussionId, filters, visible, filtersOpened }) => {
  const [allDocuments, setAllDocuments] = useState([])
  const [page, setPage] = useState(1)

  const { documents, loading } = useDocuments({
    discussionId,
    page,
    filters
  })

  const { refPaginationTrigger } = usePagination(
    documents,
    setAllDocuments,
    filters,
    page,
    setPage,
    loading
  )

  const documentsPresence = !!allDocuments.length

  return (
    <DrawerList
      className={`${className} ${filtersOpened && "filter-opened"} ${
        visible ? "" : "hidden"
      }`}
    >
      {allDocuments.map((document) => (
        <EntityRow
          key={`${document.type}-${document.id}`}
          document={document}
          discussionId={discussionId}
        />
      ))}
      <NotFound
        entitiesPresence={documentsPresence}
        loading={loading}
        text="No documents found"
      />

      <LoadMore
        entitiesPresence={documentsPresence}
        loading={loading}
        refPaginationTrigger={refPaginationTrigger}
      />
    </DrawerList>
  )
}

DocumentList.propTypes = {
  discussionId: number.isRequired,
  visible: bool.isRequired,
  filtersOpened: bool.isRequired,
  filters: shape({
    text: string,
    type: string,
    authorId: number,
    fromDate: string,
    toDate: string
  })
}

export default DocumentList
