import React from "react"

import { ThreadItem as ThreadItemType } from "src/features/UniversalAi/Sidebar/propTypes"

import ThreadItem from "../ThreadItem"

import { Container } from "./styles"

const CurrentThreadItem = ({ threadItem }) => {
  return (
    <Container>
      <ThreadItem threadItem={threadItem} />
    </Container>
  )
}

CurrentThreadItem.propTypes = {
  threadItem: ThreadItemType.isRequired
}

export default CurrentThreadItem
