import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import { PADDING_X } from "src/features/UniversalAi/Sidebar/AreaOfFocus/styles"
import FocusableItemIconBase from "src/features/UniversalAi/Sidebar/FocusableItemIcon"

export const FocusableItemIcon = styled(FocusableItemIconBase)``

export const Title = styled.span`
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

export const Button = styled(UnstyledButton)`
  width: 100%;
  padding: 8px ${PADDING_X};
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;

  // Nesting to increase specificity, override styles of button.sass
  & ${FocusableItemIcon} {
    color: #a7a7ab;
  }

  // Nesting to increase specificity, override styles of button.sass
  & ${Title} {
    margin-left: 0;
  }
`
