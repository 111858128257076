import { arrayOf, bool, number, oneOf, shape, string } from "prop-types"

import { RESULT_TYPES } from "src/features/Search/constants"
import { FocusableItemTypes, Status } from "src/features/UniversalAi/constants"

export const FocusableItem = shape({
  id: string.isRequired,
  title: string.isRequired,
  type: oneOf(FocusableItemTypes)
})

export const Source = shape({
  author: string,
  breadcrumbs: arrayOf(string),
  createdAt: string,
  documentType: string,
  except: string,
  link: string.isRequired,
  sourceType: string.isRequired,
  sourceId: number.isRequired,
  sourceDeleted: bool,
  page: number,
  title: string.isRequired
})

export const SearchResultItemBreadcrumb = shape({
  name: string.isRequired,
  path: string
})

export const SearchResultItemTypes = Object.values(RESULT_TYPES)

export const SearchResultItem = shape({
  id: string.isRequired,
  type: oneOf(SearchResultItemTypes).isRequired,
  text: string,
  title: string,
  breadcrumbs: arrayOf(SearchResultItemBreadcrumb),
  path: string,
  sourceDeleted: bool
})

export const ThreadItem = shape({
  id: string.isRequired,
  focusable: FocusableItem.isRequired,
  query: string.isRequired,
  answer: string,
  sources: arrayOf(Source),
  searchResults: arrayOf(SearchResultItem),
  status: oneOf(Object.values(Status))
})
