import React, { useState } from "react"
import { orderBy } from "lodash"
import { arrayOf, number, shape, func } from "prop-types"

import useAblyChannel from "src/hooks/useAblyChannel"
import { SectionTitle } from "src/features/Sidebar/styles"
import { CHANNELS } from "src/constants/ably"
import { ITEM_TYPE } from "src/resources/accounts/constants"

import Account from "./Account"
import { AccountsContainer } from "./styles"
import { Divider } from "./Account/styles"

const Accounts = ({ accounts, refetch }) => {
  const [focusAccountId, setFocusAccountId] = useState(null)
  const [focusAccountUpdated, setFocusAccountUpdated] = useState(false)

  const setFocusAccountAfterUpdate = (accountId) => {
    setFocusAccountUpdated(false)
    setFocusAccountId(accountId)
  }

  const onStarredItemMessage = (message) => {
    if (message.data.itemType !== ITEM_TYPE) return

    refetch().then(() => {
      if (
        focusAccountId &&
        !focusAccountUpdated &&
        message.data.itemId === focusAccountId
      )
        setFocusAccountUpdated(true)
    })
  }

  useAblyChannel(CHANNELS.users.starredItemsStatus, {
    onMessage: onStarredItemMessage
  })

  if (!accounts) return null

  const isStarAvailable = accounts.length > 1

  return (
    <AccountsContainer data-behavior="accounts-sidebar-section">
      <SectionTitle>Organisations</SectionTitle>

      <div className="sidebar-menu">
        {orderBy(accounts, ["starred", "name"], ["desc", "asc"]).map((account) => (
          <Account
            key={account.id}
            account={account}
            isStarAvailable={isStarAvailable}
            focusAccount={focusAccountUpdated && focusAccountId === account.id}
            setFocusAccountAfterUpdate={setFocusAccountAfterUpdate}
          />
        ))}
        <Divider />
      </div>
    </AccountsContainer>
  )
}

Accounts.propTypes = {
  accounts: arrayOf(
    shape({
      id: number.isRequired
    })
  ),
  refetch: func.isRequired
}

export default Accounts
