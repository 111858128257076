import React, { useEffect, useState } from "react"
import { number, string } from "prop-types"
import { captureException } from "@sentry/browser"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

import Toolbar from "./Toolbar"
import FolderItems from "./FolderItems"
import Breadcrumbs from "./Breadcrumbs"
import { Container } from "./styles"
import useFolderItems from "./useFolderItems"
import useFolderPath from "./useFolderPath"
import useGroupPermissions from "./useGroupPermissions"
import CreateFolderModal from "./CreateFolderModal"
import useCreateFolderModal from "./useCreateFolderModal"
import UploadPanelModal from "./UploadPanelModal"
import useUploadPanel from "./useUploadPanel"
import useToolbar from "./useToolbar"
import useDeleteItemsConfirmModal from "./useDeleteItemsConfirmModal"
import DeleteItemsConfirmModal from "./DeleteItemsConfirmModal"
import useMoveCopyModal from "./useMoveCopyModal"
import MoveCopyModal from "./MoveCopyModal"
import FilePreview from "./FilePreview"
import useFilePreview from "./useFilePreview"
import { GroupType } from "./types"
import GroupIdContext from "./GroupIdContext"
import CurrentFolderIdContext from "./CurrentFolderIdContext"
import FileIdContext from "./FileIdContext"
import SelectedFolderItemsContext from "./SelectedFolderItemsContext"
import useRenameModal from "./useRenameModal"
import RenameModal from "./RenameModal"
import useMoveFolderItem from "./useMoveFolderItem"
import MoveFolderItemFlashMessage from "./MoveFolderItemFlashMessage"
import useMoveFolderItemFlashMessage from "./useMoveFolderItemFlashMessage"
import useTruncate from "./useTruncate"
import PermissionsContext from "./PermissionsContext"

const DocumentsExplorer = ({ accountId, group, folderId = null, fileId = null }) => {
  const groupId = group.id

  const [currentFolderId, setCurrentFolderId] = useState(folderId || group.boxFolderId)

  const { permissions, loading: permissionsLoading } = useGroupPermissions(groupId)

  const {
    folderItems,
    selectedFolderItems,
    selectFolderItem,
    deselectFolderItem,
    selectAllFolderItems,
    deselectAllFolderItems,
    sorting,
    toggleSorting,
    error: folderItemsLoadingError,
    refetch: refetchFolderItems,
    loading: folderItemsLoading
  } = useFolderItems({
    groupId,
    folderId: currentFolderId
  })

  const { path } = useFolderPath({ groupId, folderId: currentFolderId })
  const currentFolderName = path[path.length - 1]?.name

  const {
    open: openCreateFolderModal,
    close: closeCreateFolderModal,
    isOpened: createFolderModalOpened,
    onSubmit: onCreateFolderSubmit,
    submitting: createFolderSubmitting
  } = useCreateFolderModal({
    groupId,
    folderId: currentFolderId
  })

  const {
    uploadFolder,
    uploadPanelModalOpened,
    closeUploadPanelModal,
    deselectUploadItem,
    setFiles,
    submitFiles
  } = useUploadPanel({
    groupId,
    permissions,
    folderId: currentFolderId,
    onFinished: refetchFolderItems
  })

  const { onUploadFiles, onUploadFolders, onDownload } = useToolbar({
    groupId,
    currentFolderId,
    selectedFolderItems,
    onSelectFiles: setFiles
  })

  const {
    open: openDeleteItemsConfirmModal,
    close: closeDeleteItemsConfirmModal,
    isOpened: deleteItemsConfirmModalOpened,
    onSubmit: onDeleteItemsSubmit,
    deleting: deletingFolderItems,
    completed: folderItemsDeletionComplete,
    deletionLog: folderItemsDeletionLog
  } = useDeleteItemsConfirmModal({
    groupId,
    parentFolderId: currentFolderId,
    folderItems: selectedFolderItems,
    onComplete: () => {
      deselectAllFolderItems()
      refetchFolderItems()
    }
  })

  const {
    open: openMoveCopyModal,
    close: closeMoveCopyModal,
    isOpened: moveCopyModalOpened
  } = useMoveCopyModal(group)

  const previewFile = useFilePreview()

  useEffect(() => {
    if (!folderId || !fileId || folderItemsLoading) return

    const file = folderItems.find((item) => item.id === fileId && item.type === "file")
    if (!file) return

    previewFile(file)
  }, [folderId, fileId, folderItemsLoading])

  const {
    open: openRenameModal,
    close: closeRenameModal,
    isOpened: renameModalOpened,
    onSubmit: onRenameFolderItemSubmit,
    submitting: renameFolderItemSubmitting
  } = useRenameModal({
    groupId,
    folderItem: selectedFolderItems[0],
    onComplete: deselectAllFolderItems
  })

  const {
    visible: moveFolderItemFlashVisible,
    level: moveFolderItemFlashLevel,
    message: moveFolderItemFlashMessage,
    withUndo: moveFolderItemUndoEnabled,
    show: showMoveFolderItemFlashMessage,
    close: closeMoveFolderItemFlashMessage
  } = useMoveFolderItemFlashMessage()

  const truncate = useTruncate()

  const { move: moveFolderItem, undo: undoMoveFolderItem } = useMoveFolderItem({
    groupId,
    currentFolder: { id: currentFolderId, name: currentFolderName },
    onMoveStart: ({ folderItem, destinationFolder }) => {
      showMoveFolderItemFlashMessage({
        level: "warning",
        message: `Moving ${truncate(folderItem.name)} into "${truncate(destinationFolder.name)}"`
      })
    },
    onMoveSuccess: ({ folderItem, destinationFolder }) => {
      const message = `${truncate(folderItem.name)} has been moved from "${truncate(currentFolderName)}" to "${truncate(destinationFolder.name)}"`
      showMoveFolderItemFlashMessage({ message, level: "info", withUndo: true })
    },
    onUndoStart: ({ folderItem, destinationFolder }) => {
      showMoveFolderItemFlashMessage({
        level: "warning",
        message: `Moving ${truncate(folderItem.name)} back into "${truncate(destinationFolder.name)}"`
      })
    },
    onUndoSuccess: ({ folderItem, destinationFolder }) => {
      const message = `${truncate(folderItem.name)} has been moved back to "${truncate(destinationFolder.name)}"`
      showMoveFolderItemFlashMessage({ message, level: "info" })
    },
    onFailedResult: (message) => {
      showMoveFolderItemFlashMessage({ level: "danger", message })
    },
    onError: (error) => {
      captureException(error)
      showMoveFolderItemFlashMessage({
        level: "danger",
        message: "Something went wrong, please, try again later"
      })
    }
  })

  const onNavigate = (navigatedFolderId) => {
    setCurrentFolderId(navigatedFolderId)
    deselectAllFolderItems()
  }

  const onMoveCopyComplete = () => {
    deselectAllFolderItems()
    refetchFolderItems()
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <PermissionsContext permissions={permissions}>
        <GroupIdContext groupId={groupId}>
          <CurrentFolderIdContext folderId={currentFolderId}>
            <FileIdContext fileId={fileId}>
              <SelectedFolderItemsContext selectedFolderItems={selectedFolderItems}>
                {moveFolderItemFlashVisible && (
                  <MoveFolderItemFlashMessage
                    level={moveFolderItemFlashLevel}
                    message={moveFolderItemFlashMessage}
                    onClose={closeMoveFolderItemFlashMessage}
                    withUndo={moveFolderItemUndoEnabled}
                    onUndo={undoMoveFolderItem}
                  />
                )}

                <Container data-testid="documents-explorer">
                  <Breadcrumbs path={path} onNavigate={onNavigate} />

                  <Toolbar
                    folderItemsCount={folderItems.length}
                    selectedFolderItemsCount={selectedFolderItems.length}
                    loading={permissionsLoading}
                    onNewFolder={openCreateFolderModal}
                    onUploadFiles={onUploadFiles}
                    onUploadFolders={onUploadFolders}
                    onDelete={openDeleteItemsConfirmModal}
                    onDeselectAll={deselectAllFolderItems}
                    onMoveCopy={openMoveCopyModal}
                    onDownload={onDownload}
                    onRename={openRenameModal}
                  />

                  <FolderItems
                    folderItems={folderItems}
                    selectedFolderItems={selectedFolderItems}
                    loading={folderItemsLoading}
                    error={folderItemsLoadingError}
                    onNavigate={onNavigate}
                    onDropFiles={setFiles}
                    onSelect={selectFolderItem}
                    onDeselect={deselectFolderItem}
                    onSelectAll={selectAllFolderItems}
                    onDeselectAll={deselectAllFolderItems}
                    onPreview={previewFile}
                    sorting={sorting}
                    onSortingToggle={toggleSorting}
                    onMove={moveFolderItem}
                  />

                  {uploadPanelModalOpened && (
                    <UploadPanelModal
                      uploadFolder={uploadFolder}
                      onClose={closeUploadPanelModal}
                      onSubmit={submitFiles}
                      onRemove={deselectUploadItem}
                    />
                  )}

                  {createFolderModalOpened && (
                    <CreateFolderModal
                      onSubmit={onCreateFolderSubmit}
                      onClose={closeCreateFolderModal}
                      submitting={createFolderSubmitting}
                    />
                  )}

                  {deleteItemsConfirmModalOpened && (
                    <DeleteItemsConfirmModal
                      onSubmit={onDeleteItemsSubmit}
                      onClose={closeDeleteItemsConfirmModal}
                      deleting={deletingFolderItems}
                      log={folderItemsDeletionLog}
                      completed={folderItemsDeletionComplete}
                      total={selectedFolderItems.length}
                    />
                  )}

                  {moveCopyModalOpened && (
                    <MoveCopyModal
                      selectedFolderItems={selectedFolderItems}
                      accountId={accountId}
                      group={group}
                      openedFolderId={currentFolderId}
                      isOpened={moveCopyModalOpened}
                      close={closeMoveCopyModal}
                      onComplete={onMoveCopyComplete}
                    />
                  )}

                  {renameModalOpened && (
                    <RenameModal
                      folderItem={selectedFolderItems[0]}
                      onClose={closeRenameModal}
                      onSubmit={onRenameFolderItemSubmit}
                      submitting={renameFolderItemSubmitting}
                    />
                  )}

                  <FilePreview
                    accountId={accountId}
                    groupId={groupId}
                    folderId={currentFolderId}
                    sidebarEnabled={permissions?.canCreateFolder}
                  />
                </Container>
              </SelectedFolderItemsContext>
            </FileIdContext>
          </CurrentFolderIdContext>
        </GroupIdContext>
      </PermissionsContext>
    </DndProvider>
  )
}

DocumentsExplorer.propTypes = {
  accountId: number.isRequired,
  folderId: string,
  fileId: string,
  group: GroupType.isRequired
}

export default DocumentsExplorer
