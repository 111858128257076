import React from "react"
import { instanceOf, func } from "prop-types"

import { FullScreenModal as Modal, Header } from "src/styles/components/Modal"

import UploadFolder from "../UploadFolder"

import FolderItemsList from "./FolderItemsList"
import { Body, Footer } from "./styles"

const UploadPanelModal = ({ uploadFolder, onClose, onSubmit, onRemove }) => {
  const uploading = uploadFolder.isUploading()
  const uploadFinished = uploadFolder.isUploadFinished()

  return (
    <Modal show onHide={onClose} backdrop="static">
      <Header>Review documents</Header>
      <Body>
        <FolderItemsList folder={uploadFolder} onRemove={onRemove} />
      </Body>
      <Footer>
        <button className="button is-secondary" onClick={onClose} disabled={uploading}>
          Cancel
        </button>
        <button
          type="submit"
          className="button"
          onClick={onSubmit}
          disabled={uploading || uploadFinished}
        >
          {!uploading && "Upload"}
          {uploading && "Uploading..."}
        </button>
      </Footer>
    </Modal>
  )
}

UploadPanelModal.propTypes = {
  uploadFolder: instanceOf(UploadFolder).isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  onRemove: func.isRequired
}

export default UploadPanelModal
