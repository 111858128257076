import { styled } from "@linaria/react"
import { css } from "@linaria/core"

import { FONT_WEIGHTS } from "src/styles/sizes"

export const OverlayClass = css`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 400px;
  box-shadow: 0px 0px 10px 2px #e3e7ee;
  border-radius: 10px 0 0 10px;
`

export const FullscreenClass = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: none;
`

export const Aside = styled.aside`
  flex: 0 0 400px;
  background-color: white;
  border-left: 2px solid #e3e7ed;
  display: flex;
  flex-direction: column;
  // Must be higher that z-index of .header and .main-menu
  // (specified in header.sass and main-menu.sass)
  z-index: 5;
  max-width: 400px;

  &.${FullscreenClass} {
    max-width: none;
  }
`

export const Header = styled.div`
  flex-shrink: 0;
  padding: 10px 16px 9px;
  border-bottom: 1px solid #e3e7ed;
  display: flex;
  justify-content: space-between;
  min-height: 49px;
`

export const Toolbar = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
`

export const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const Content = styled.div`
  padding-right: 10px;
  overflow: auto;

  h6:first-of-type {
    display: none;
  }
`

export const Title = styled.div`
  flex-grow: 1;
  line-height: 1.5;
  font-weight: ${FONT_WEIGHTS.xBold};
  font-family: "Space Grotesk", sans-serif;

  &.semiBold {
    font-weight: ${FONT_WEIGHTS.semiBold};
  }

  * {
    display: none;
  }

  h6:first-of-type {
    display: inline;
    line-height: 1.5;
    font-weight: ${FONT_WEIGHTS.xBold};
  }
`
