import { getCurrentUserId } from "src/helpers/user"
import { getAllAnnotations } from "src/features/signedDocuments/helpers"

const signAllButton = (instance, storedSignature) => {
  const currentUserId = getCurrentUserId()

  return {
    type: "custom",
    className: "custom-button jump-to-next-button",
    id: "sign-all-button",
    title: "Sign all",
    async onPress() {
      const formFields = await instance.getFormFields()
      const annotations = await getAllAnnotations(instance)
      const { List } = window.PSPDFKit.Immutable
      const { DrawingPoint, Rect } = window.PSPDFKit.Geometry
      const { InkAnnotation } = window.PSPDFKit.Annotations

      const formFieldsAnnotations = formFields
        .map((ff) => ff?.annotationIds?.toArray())
        .toArray()
        .flat()
        .filter(Boolean)
        .map((annotationId) =>
          annotations
            .find(
              ({ id, customData }) =>
                customData?.assignedUserId === currentUserId && annotationId === id
            )
            ?.toJS()
        )
        .filter(Boolean)

      const signedAnnotationIds = annotations
        .filter((annotation) => annotation.isSignature)
        .map((annotation) => annotation?.customData?.signatureFieldId)
        .filter(Boolean)

      const unsignedAnnotations = formFieldsAnnotations
        .filter(
          (annotation) =>
            !signedAnnotationIds.includes(annotation.id) &&
            annotation.formFieldName?.includes("SIGNATURE_WIDGET")
        )
        .sort((first, second) => first.pageIndex - second.pageIndex)

      const serializedStoredSignature =
        window.PSPDFKit.Annotations.fromSerializableObject(storedSignature)

      unsignedAnnotations.forEach((unsignedAnnotation) => {
        const { pageIndex, boundingBox, id } = unsignedAnnotation
        const widthProportion =
          boundingBox.width / serializedStoredSignature.boundingBox.width
        const heightProportion =
          boundingBox.height / serializedStoredSignature.boundingBox.height
        const proportion = Math.min(widthProportion, heightProportion)

        // Calculate bounding box
        let width
        let height
        let left
        let top
        if (heightProportion > widthProportion) {
          width = boundingBox.width
          height = serializedStoredSignature.boundingBox.height * proportion
          left = boundingBox.left
          top = boundingBox.top + (boundingBox.height - height) * 0.5
        } else {
          width = serializedStoredSignature.boundingBox.width * proportion
          height = boundingBox.height
          left = boundingBox.left + (boundingBox.width - width) * 0.5
          top = boundingBox.top
        }

        const annotation = new InkAnnotation({
          pageIndex,
          boundingBox: new Rect({ left, top, width, height }),
          lines: List([
            ...storedSignature.lines.points.map((points) =>
              List([
                ...points.map(
                  (point) =>
                    new DrawingPoint({
                      x: left + point[0] * proportion,
                      y: top + point[1] * proportion
                    })
                )
              ])
            )
          ]),
          lineWidth: 2,
          strokeColor: serializedStoredSignature.strokeColor,
          isSignature: true,
          opacity: 1,
          customData: { signatureFieldId: id, signedUserId: currentUserId }
        })

        instance.create(annotation)
      })
    }
  }
}

export default signAllButton
