import React from "react"
import { styled } from "@linaria/react"
import { cx } from "@linaria/core"
import { arrayOf, func, number, shape, string, bool } from "prop-types"

import { BREAKPOINTS, FONT_SIZES, messageBlockPadding, SPACES } from "src/styles/sizes"
import { BASE_COLORS } from "src/styles/colors"
import LinkButton from "src/styles/components/Button/Link"
import useIsOpened from "src/hooks/useIsOpened"
import { getCurrentUserId } from "src/helpers/user"

import SignedPeopleMessage from "./SignedPeopleMessage"
import SignatoriesModal from "./SignatoriesModal"
import CurrentUserSignatureStatus from "./CurrentUserSignatureStatus"
import ClosedMessage from "./ClosedMessage"
import CloseActionModal from "./CloseActionModal"

const SignatoriesContainer = styled.div`
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
`

const SignatoriesWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 1.44rem;
`

const SignatoriesPanel = ({
  isCurrentUserAuthor,
  document,
  users,
  onSignButtonClick,
  className
}) => {
  const closeActionModal = useIsOpened()
  const signatoriesModal = useIsOpened()

  const currentUserId = getCurrentUserId()
  const currentUser = users.find(({ id }) => id === currentUserId)
  const shouldBeSignedByUser = currentUser && !currentUser.signedAt
  const showCloseActions = isCurrentUserAuthor && !document.closedAt && !document.signedAt

  const closeAllModals = () => {
    closeActionModal.close()
    signatoriesModal.close()
  }

  return (
    <div className={cx(className, shouldBeSignedByUser && "sign")}>
      <SignatoriesContainer>
        <SignatoriesWrapper>
          {!document.closedAt && (
            <SignedPeopleMessage users={users} signedAt={document.signedAt} />
          )}
          {document.closedAt && <ClosedMessage closedAt={document.closedAt} />}
          &nbsp;
          <LinkButton onClick={signatoriesModal.open} className="no-print">
            View details
          </LinkButton>
        </SignatoriesWrapper>
        <CloseActionModal
          show={closeActionModal.isOpened}
          close={closeActionModal.close}
          closeAllModals={closeAllModals}
          document={document}
        />
        <SignatoriesModal
          users={users}
          isOpened={signatoriesModal.isOpened}
          close={signatoriesModal.close}
          showCloseActions={showCloseActions}
          onSelectCloseAction={closeActionModal.open}
        />
      </SignatoriesContainer>
      <CurrentUserSignatureStatus
        users={users}
        disabled={!!document.closedAt}
        onSignButtonClick={onSignButtonClick}
      />
    </div>
  )
}

SignatoriesPanel.propTypes = {
  isCurrentUserAuthor: bool.isRequired,
  document: shape({
    name: string.isRequired,
    signedAt: string,
    closedAt: string,
    messageId: number.isRequired
  }).isRequired,
  users: arrayOf(
    shape({
      id: number
    })
  ),
  onSignButtonClick: func
}

export default styled(SignatoriesPanel)`
  border-top: 1px solid ${BASE_COLORS.beige};
  font-size: ${FONT_SIZES.small};
  margin: ${SPACES.xSmall} -${messageBlockPadding} -${messageBlockPadding} -${messageBlockPadding};
  padding: ${SPACES.xSmall} ${messageBlockPadding};
  display: flex;
  justify-content: space-between;

  &.sign {
    padding: ${SPACES.xxSmall} ${messageBlockPadding};
  }

  @media (max-width: ${BREAKPOINTS.xSmall}) {
    flex-direction: column;
    align-items: center;
  }
`
