import React from "react"
import { bool, elementType, number, shape, string } from "prop-types"

import AlertBanner from "src/styles/components/AlertBanner"

import BetaLabel from "./BetaLabel"

const Header = ({ meetingPackId, account, SubHeaderActionsComponent, ...rest }) => {
  return (
    <>
      <div className="subheader-nav subheader-nav-grid groups-list-nav">
        <div className="subheader-link">
          <a
            href={`/meeting_packs/${meetingPackId}`}
            className="subheader-control button is-secondary"
          >
            <span className="control-text">Back to Meeting Pack</span>
          </a>
        </div>
        <div className="subheader-title">
          <h5>Knowa Verse</h5>
          <BetaLabel />
        </div>
        {SubHeaderActionsComponent && (
          <div className="subheader-actions">
            <SubHeaderActionsComponent {...rest} />
          </div>
        )}
      </div>

      {!account.hasVerseSettings && (
        <AlertBanner
          level="warning"
          text={`Get in touch with Knowa support to provide a sample set of minutes for the ${account.name} org.`}
        />
      )}
    </>
  )
}

Header.propTypes = {
  meetingPackId: number.isRequired,
  account: shape({
    name: string.isRequired,
    hasVerseSettings: bool.isRequired
  }),
  SubHeaderActionsComponent: elementType
}

export default Header
