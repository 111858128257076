import React from "react"
import { arrayOf, func, string } from "prop-types"

import { FocusableItem } from "src/features/UniversalAi/Sidebar/propTypes"

import { Title } from "./styles"
import SectionItem from "./SectionItem"

const Section = ({ title, items, onSelect }) => {
  if (!items || items?.length === 0) {
    return null
  }

  return (
    <div>
      {title && <Title>{title}</Title>}

      {items.map((item) => (
        <SectionItem
          key={`${item.type}-${item.id}`}
          item={item}
          onClick={() => onSelect(item)}
        />
      ))}
    </div>
  )
}

Section.propTypes = {
  title: string,
  items: arrayOf(FocusableItem).isRequired,
  onSelect: func.isRequired
}

export default Section
