import React from "react"

import { SearchResultItem } from "src/features/UniversalAi/Sidebar/propTypes"

import { Container } from "./styles"
import Title from "./Title"
import Breadcrumbs from "./Breadcrumbs"
import Excerpt from "./Excerpt"

const ResultItem = ({ resultItem }) => {
  return (
    <Container>
      <Title resultItem={resultItem} />
      <Breadcrumbs breadcrumbs={resultItem.breadcrumbs} />
      <Excerpt text={resultItem.text} />
    </Container>
  )
}

ResultItem.propTypes = {
  resultItem: SearchResultItem.isRequired
}

export default ResultItem
