import { styled } from "@linaria/react"

import FocusableItemIconBase from "src/features/UniversalAi/Sidebar/FocusableItemIcon"

export const FocusableItemIcon = styled(FocusableItemIconBase)``

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
`

export const Header = styled.div`
  text-align: center;
  height: 49px;
  position: relative;
`

export const HeaderLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d3d3d5;
  position: absolute;
  top: 50%;
  z-index: 1;
`

export const HeaderTitle = styled.div`
  position: absolute;
  background-color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  max-width: 290px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Space Grotesk";
  font-size: 14px;
  font-weight: 500;
  color: #4f4f58;
  display: flex;
  align-items: center;

  & ${FocusableItemIcon} {
    color: #727279;
    margin-right: 5px;
    font-size: 15px;
  }
`

export const HeaderLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`
