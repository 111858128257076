import { styled } from "@linaria/react"

const Label = styled.div`
  text-align: left;
  color: #9647d7;
  font-weight: 700;
`

export const YouLabel = styled(Label)`
  margin-bottom: 5px;
`

export const KnowaQLabel = styled(Label)`
  margin-bottom: 8px;
`

export const QueryContainer = styled.div`
  font-weight: 700;
  margin-bottom: 16px;
  font-family: "Space Grotesk";
`
