import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import { BASE_TEXT_COLOR } from "src/features/UniversalAi/Sidebar/styles"
import Icon from "src/styles/components/Icon"

export const Container = styled.div`
  padding: 12px 16px;
  border: 1px solid #e9e9ea;
  border-radius: 10px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`

export const Toggle = styled(UnstyledButton)`
  color: ${BASE_TEXT_COLOR};
  font-size: 14px;
  line-height: 20px;
  font-family: "Space Grotesk";
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ToggleIcon = styled(Icon)`
  font-weight: bold;
  position: relative;
  top: 1px;

  &.opened {
    transform: rotate(180deg);
    top: 0;
    left: 1px;
  }
`

export const ContentWrapper = styled.div`
  margin-top: 16px;
`
