import React from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { number, shape, string } from "prop-types"

import UserAvatar from "src/components/UsersContacts/UserAvatar"
import useIsOpened from "src/hooks/useIsOpened"
import { showIntercom } from "src/helpers/intercom"
import { withDefaultPrevented } from "src/helpers/events"
import { isOnThePage } from "src/helpers/url"
import { TEXT_COLORS } from "src/styles/colors"

const UserMenu = ({ className, user }) => {
  const { toggle, isOpened } = useIsOpened(false)
  const { initials, avatarColorId, avatarUrl, fullName } = user

  if (![initials, avatarColorId, avatarUrl, fullName].some(Boolean)) {
    return null
  }

  return (
    <div
      className={classNames(`${className} sidebar-section sidebar-user-menu`, {
        highlighted: isOnThePage("profile")
      })}
    >
      <ul className="navbar-nav user-menu">
        <li className="nav-item">
          <button
            onClick={toggle}
            className={classNames("nav-link", "dropdown-toggle", {
              expanded: isOpened
            })}
            data-testid="user-menu"
          >
            <div className="user-card">
              <UserAvatar className="d-inline-block" user={user} />
              <span className="user-name ml-2 text-truncate">{fullName}</span>
            </div>
          </button>
          <ul className="user-actions">
            <li>
              <a href="/users/profile/edit">Settings</a>
            </li>
            <li>
              <a
                href="//help.knowa.co"
                onClick={() => window.analytics.track("Support_View-help")}
                target="_blank"
                rel="noreferrer"
              >
                Tutorials & FAQs
              </a>
            </li>
            <li>
              <a
                href="/"
                onClick={withDefaultPrevented(showIntercom)}
                target="_blank"
                rel="noreferrer"
              >
                Contact Support
              </a>
            </li>
            <li>
              <a href="/users/sign_out" data-method="delete" data-remote="true">
                Log out
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}

UserMenu.propTypes = {
  user: shape({
    id: number.isRequired,
    initials: string,
    avatarColorId: number,
    avatarUrl: string,
    fullName: string
  }).isRequired
}

export default styled(UserMenu)`
  .nav-link {
    background: transparent;
    color: ${TEXT_COLORS.sidebar};
    border: none;
    width: 100%;
  }

  li {
    color: ${TEXT_COLORS.sidebar};
  }
`
