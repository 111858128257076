import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { sample } from "lodash"

import { actions } from "src/features/UniversalAi/store/slice"
import useUniversalAiSelector from "src/features/UniversalAi/store/useUniversalAiSelector"

const useChannelName = (channelType, channelsList) => {
  const dispatch = useDispatch()
  const { channels } = useUniversalAiSelector()

  useEffect(() => {
    const existingChannel = channels[channelType]
    if (existingChannel) return

    const newChannel = sample(channelsList)
    dispatch(actions.saveChannel({ channelType, channelName: newChannel }))
  }, [channelType])

  return channels[channelType]
}

export default useChannelName
