import React, { useContext } from "react"
import { string, shape, number, bool } from "prop-types"
import { Dropdown } from "react-bootstrap"

import { getCurrentUser } from "src/helpers/user"
import ModalContext from "src/features/Messages/ActionDetails/ModalContext"
import { ACTION_TYPES, MODAL_TYPES } from "src/features/Messages/ActionDetails/constants"

import DropdownItem from "./DropdownItem"
import Button from "./Button"

const TITLE = {
  must_read: "Must Read options",
  voting: "Vote options"
}

const NUMBER_ACTION = {
  must_read: "Number read",
  voting: "Votes in agreement"
}

const CloseActionButton = ({ message, className }) => {
  const { userId, actionType, actionClosed, actionClosedAt, maxActionsCount } = message
  const currentUser = getCurrentUser()

  const { openCloseActionModal } = useContext(ModalContext)
  const onSelect = (eventKey) => openCloseActionModal(eventKey)

  const isAuthor = userId === currentUser.id
  const showCloseButton = isAuthor && !actionClosed

  if (!showCloseButton) return null

  const showOptions = !actionClosedAt && !maxActionsCount
  const showDateOption = showOptions
  const showNumberOption =
    showOptions &&
    (message.actionType !== ACTION_TYPES.voting || message.actionsCount > 1)

  return (
    <Dropdown align="end" onSelect={onSelect}>
      <Dropdown.Toggle as={Button} className={className}>
        {TITLE[actionType]}
      </Dropdown.Toggle>

      <Dropdown.Menu align="end" alignRight>
        <DropdownItem eventKey={MODAL_TYPES.now} text="Close immediately" />
        {showDateOption && (
          <DropdownItem eventKey={MODAL_TYPES.date} text="Close on a date" />
        )}
        {showNumberOption && (
          <DropdownItem eventKey={MODAL_TYPES.number} text={NUMBER_ACTION[actionType]} />
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

CloseActionButton.propTypes = {
  message: shape({
    id: number.isRequired,
    actionClosedAt: string,
    userId: number.isRequired,
    actionType: string.isRequired,
    maxActionsCount: number,
    actionClosed: bool
  }),
  className: string
}

export default CloseActionButton
