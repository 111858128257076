import React, { useState } from "react"
import { arrayOf, bool, number, shape, string } from "prop-types"

import useAblyChannel from "src/hooks/useAblyChannel"
import { TRANSCRIPT_STATUSES } from "src/resources/meetingPacks/constants"
import { CHANNELS } from "src/constants/ably"

import UploadForm from "./UploadForm"
import Header from "./Header"
import View from "./View"
import Pending from "./Pending"

const Transcript = ({ transcript }) => {
  const [status, setStatus] = useState(transcript?.status)
  const [transcriptId, setTranscriptId] = useState(transcript?.id)

  useAblyChannel(CHANNELS.meetingPacksTranscript, {
    onMessage: ({ data, name }) => {
      if (name === "update" && data.meeting_pack_id === transcript.meetingPackId) {
        setTranscriptId(data.id)
        setStatus(data.status)
      }
    }
  })

  const completed = status === TRANSCRIPT_STATUSES.complete
  const pending = status && status !== TRANSCRIPT_STATUSES.failed && !completed
  const accountData = {
    name: transcript.accountName,
    hasVerseSettings: transcript.accountHasVerseSettings
  }

  return (
    <>
      {!completed && (
        <Header meetingPackId={transcript.meetingPackId} account={accountData} />
      )}

      {completed && (
        <View
          meetingPackId={transcript.meetingPackId}
          account={accountData}
          transcriptId={transcriptId}
        />
      )}
      {pending && (
        <Pending className={transcript.accountHasVerseSettings ? "mt-4" : "mt-2"} />
      )}
      {!completed && !pending && (
        <UploadForm
          meetingPackId={transcript.meetingPackId}
          setStatus={setStatus}
          status={status}
          className={transcript.accountHasVerseSettings ? "mt-5" : "mt-2"}
        />
      )}
    </>
  )
}

Transcript.propTypes = {
  transcript: shape({
    id: number,
    status: string,
    content: string,
    utterances: arrayOf(
      shape({
        start: number,
        end: number,
        speaker: string,
        text: string.isRequired
      })
    ),
    accountName: string.isRequired,
    accountHasVerseSettings: bool.isRequired,
    meetingPackId: number.isRequired
  })
}

export default Transcript
