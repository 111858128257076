import React, { forwardRef } from "react"
import { arrayOf, bool, func } from "prop-types"
import { debounce } from "lodash"

import { FocusableItem } from "src/features/UniversalAi/Sidebar/propTypes"

import { Divider, Menu, SearchContainer, SearchInput, Message } from "./styles"
import Section from "./Section"

const SEARCH_INPUT_CHANGE_DELAY = 300

const DropdownMenu = forwardRef(
  (
    {
      recentItems,
      suggestions,
      searchResults,
      sectionsLoading,
      searchResultsLoading,
      onSelect,
      onSearchQueryChange
    },
    ref
  ) => {
    const showSearchResults = searchResults?.length === 0 || searchResults?.length > 0

    const debouncedSearchInputChange = debounce((query) => {
      onSearchQueryChange(query)
    }, SEARCH_INPUT_CHANGE_DELAY)

    const handleSearchInputChange = (e) => {
      debouncedSearchInputChange(e.target.value)
    }

    return (
      <Menu ref={ref}>
        {sectionsLoading && <Message>Loading...</Message>}

        {!sectionsLoading && (
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder="Specify area for query e.g. Q1 Meeting"
              onChange={handleSearchInputChange}
              autoFocus
            />
          </SearchContainer>
        )}

        {!sectionsLoading && !searchResultsLoading && !showSearchResults && (
          <>
            <Section title="Recent" items={recentItems} onSelect={onSelect} />

            {recentItems.length > 0 && suggestions.length > 0 && <Divider />}

            <Section title="Suggested" items={suggestions} onSelect={onSelect} />
          </>
        )}

        {searchResultsLoading && <Message>Searching...</Message>}

        {!searchResultsLoading && searchResults?.length === 0 && (
          <Message>No results found.</Message>
        )}

        {!searchResultsLoading && searchResults?.length > 0 && (
          <Section items={searchResults} onSelect={onSelect} />
        )}
      </Menu>
    )
  }
)

DropdownMenu.propTypes = {
  recentItems: arrayOf(FocusableItem).isRequired,
  suggestions: arrayOf(FocusableItem).isRequired,
  searchResults: arrayOf(FocusableItem),
  sectionsLoading: bool.isRequired,
  searchResultsLoading: bool.isRequired,
  onSelect: func.isRequired,
  onSearchQueryChange: func.isRequired
}

export default DropdownMenu
