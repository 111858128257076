import { useQuery } from "@apollo/client"

import { FocusableItemType } from "src/features/UniversalAi/constants"
import { getCurrentMainSchemeId, getCurrentThread } from "src/helpers/document"

import currentPageFocusableItemQuery from "./currentPageFocusableItemQuery.gql"

const THREAD_TYPE_TO_FOCUSABLE = {
  MeetingPackThread: FocusableItemType.MeetingPack,
  DiscussionThread: FocusableItemType.Discussion
}

const getFocusableFromCurrentThread = () => {
  const currentThread = getCurrentThread()

  if (!currentThread) return null

  const type = THREAD_TYPE_TO_FOCUSABLE[currentThread.type]
  if (!type) return null

  return { id: currentThread.id, type }
}

const getFocusableFromCurrentScheme = () => {
  const currentSchemeId = getCurrentMainSchemeId()
  if (!currentSchemeId) return null

  return { id: currentSchemeId, type: FocusableItemType.PrivateScheme }
}

const findCurrentPageFocusable = () => {
  const focusableFromThread = getFocusableFromCurrentThread()
  if (focusableFromThread) return focusableFromThread

  const focusableFromScheme = getFocusableFromCurrentScheme()
  if (focusableFromScheme) return focusableFromScheme

  return null
}

const useCurrentPageFocusableItem = () => {
  const currentPageFocusable = findCurrentPageFocusable()

  const { data } = useQuery(currentPageFocusableItemQuery, {
    variables: currentPageFocusable,
    fetchPolicy: "cache-first",
    skip: !currentPageFocusable
  })

  const currentPageFocusableItem = data?.aiAreaOfFocusFocusedItem

  return currentPageFocusableItem
}

export default useCurrentPageFocusableItem
