import React from "react"
import { styled } from "@linaria/react"

const NoContent = ({ className }) => {
  return (
    <div className={className}>
      <div className="card">
        Sorry we can&apos;t find anything to transcribe in the audio file.
      </div>
    </div>
  )
}

export default styled(NoContent)`
  .card {
    margin-left: 85px;
  }
`
