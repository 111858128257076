import React from "react"
import classNames from "classnames"
import { bool, number, string } from "prop-types"

import { MenuItem } from "src/features/Sidebar/styles"

const Scheme = ({ id, badge, path, current, name }) => {
  return (
    <MenuItem
      key={id}
      className={classNames(badge, { current })}
      data-testid="sidebar-scheme"
      data-id={id}
    >
      <a href={path} className="menu-link collab-space-menu-link">
        {name}
      </a>
    </MenuItem>
  )
}

Scheme.propTypes = {
  id: number.isRequired,
  badge: string.isRequired,
  current: bool.isRequired,
  name: string.isRequired,
  path: string.isRequired
}

export default Scheme
