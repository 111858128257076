import { useQuery } from "@apollo/client"

import threadItemsQuery from "./threadItemsQuery.gql"

const useThreadItems = () => {
  const { data, loading, refetch } = useQuery(threadItemsQuery, {
    fetchPolicy: "cache-first"
  })

  const threadItems = data?.aiThreadItems || []

  return { threadItems, refetch, loading }
}

export default useThreadItems
