import { styled } from "@linaria/react"

export const BoxPreviewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  height: 100%;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e3e7ed;
  z-index: 3;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1.875rem 1rem 1rem;
  border-bottom: 1px solid #e3e7ed;
  height: 64px;
  line-height: 2;
`

export const TitleContainer = styled.div`
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
`

export const Title = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Preview = styled.div`
  flex-grow: 1;
`

export const Sidebar = styled.div`
  flex-shrink: 0;
  border-left: 1px solid #e8e8e8;
`
