import initialState from "./initialState"

export const setCurrentEntity = (state, action) => {
  const { id, type } = action.payload

  state.id = id
  state.type = type
}

export const clearCurrentEntity = (state, _action) => {
  state.id = initialState.id
  state.type = initialState.type
}
