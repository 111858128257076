import Qs from "qs"
import { useQuery } from "@apollo/client"

import { useDocumentPreview } from "src/features/DocumentPreview"

import attachedDocumentQuery from "./attachedDocumentQuery.gql"

const DocumentPreviewFromQuery = () => {
  const { open } = useDocumentPreview()

  const queryParams = Qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const documentId = queryParams.document_id

  useQuery(attachedDocumentQuery, {
    variables: { id: documentId },
    skip: !documentId,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const document = data?.attachedDocument
      open({ document })
    }
  })

  return null
}

export default DocumentPreviewFromQuery
