import React from "react"
import { shape, string, number } from "prop-types"
import { styled } from "@linaria/react"

import FileIcon from "src/styles/components/FileIcon"
import { splitFilename } from "src/helpers/string"
import UnstyledButton from "src/components/UnstyledButton"
import { useDocumentPreview } from "src/features/DocumentPreview"
import useMobileScreen from "src/hooks/useMobileScreen"

import useDrawerContext from "../useDrawerContext"
import { DrawerItem, DrawerItemWrapper, EntityName } from "../styles"
import MetadataLink from "../MedatataLink"
import { DRAWER_CONTENT_WIDTH } from "../constants"

const EntityRow = ({ document, className }) => {
  const { extension } = splitFilename(document.filename)
  const { open: openDocumentPreview } = useDocumentPreview()
  const { close: closeDrawer } = useDrawerContext()
  const isMobile = useMobileScreen()

  const authorsName = document.message.author.fullName
  const { threadId } = document.message
  const messageId = document.message.id

  const handleDocumentPreviewOpen = () => {
    if (isMobile) closeDrawer()

    if (!document.boxFileId) {
      window.Turbo.visit(`/discussions/${threadId}?message_id=${messageId}`)
    } else {
      openDocumentPreview({ document })
    }
  }

  return (
    <DrawerItem>
      <DrawerItemWrapper className="text-link">
        <UnstyledButton onClick={handleDocumentPreviewOpen}>
          <FileIcon extension={extension} />
        </UnstyledButton>
        <div className={className}>
          <EntityName
            onClick={handleDocumentPreviewOpen}
            title={document.filename}
            className="entity-name"
          >
            {document.filename}
          </EntityName>
          <MetadataLink
            href={`/discussions/${threadId}?message_id=${messageId}`}
            createdAt={document.createdAt}
            authorsName={authorsName}
          />
        </div>
      </DrawerItemWrapper>
    </DrawerItem>
  )
}

EntityRow.propTypes = {
  document: shape({
    boxFileId: string,
    filename: string.isRequired,
    id: number.isRequired,
    message: shape({
      id: number.isRequired,
      threadId: number.isRequired,
      author: shape({
        fullName: string.isRequired
      }).isRequired
    }).isRequired,
    createdAt: string.isRequired
  }).isRequired
}

export default styled(EntityRow)`
  max-width: calc(${DRAWER_CONTENT_WIDTH} - 13px);
`
