import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"

import { TRANSITION_PROPERTIES } from "../styles"

export const TitleContainer = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #4f4f58;
  font-family: "Space Grotesk";
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 0;
  opacity: 0;
  transition:
    top ${TRANSITION_PROPERTIES},
    opacity ${TRANSITION_PROPERTIES};
`

export const Button = styled(UnstyledButton)`
  position: absolute;
  right: 8px;
  bottom: 5px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgba(150, 71, 215, 1);
  color: white;
  transition:
    width ${TRANSITION_PROPERTIES},
    height ${TRANSITION_PROPERTIES};

  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:focus-visible:not(:disabled) {
    color: white;
    background-color: rgba(150, 71, 215, 1);
  }

  &:disabled {
    color: white;
    background-color: rgba(233, 233, 234, 1);
  }
`

export const Icon = styled.span`
  width: 100%;
  line-height: 32px;
  font-size: 15px;
  transition:
    font-size ${TRANSITION_PROPERTIES},
    line-height ${TRANSITION_PROPERTIES};

  &.small {
    font-size: 13px;
  }
`

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  padding-left: 16px;
  padding-right: 50px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(251, 251, 251, 1);
  transition:
    padding-top ${TRANSITION_PROPERTIES},
    padding-bottom ${TRANSITION_PROPERTIES};
`

export const BorderContainer = styled.div`
  position: relative;
  border: 1px solid transparent;
  border-radius: 8px;
  background-image: radial-gradient(
    97.76% 141.96% at 0% 6.25%,
    #9647d7 14.58%,
    #cba3eb 47.92%,
    #1a7ef3 75%,
    #00cc7e 100%
  );
  background-origin: border-box;
  background-clip: border-box;
  margin-top: 0;
  transition: margin-top ${TRANSITION_PROPERTIES};

  &.compact {
    margin-top: 30px;
  }

  &.compact ${TitleContainer} {
    opacity: 1;
    top: -30px;
  }

  &.compact ${Button} {
    width: 25px;
    height: 25px;
  }

  &.compact ${Icon} {
    line-height: 25px;
    font-size: 11px;
  }

  &.compact ${InputContainer} {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`

export const Textarea = styled.textarea`
  height: 20px; // recalculated on input change, must be set to avoid "blinking" on navigation
  line-height: 20px;
  min-height: 20px; // must be equal to line-height
  max-height: 60px; // 3 lines of text
  padding: 0;
  resize: none;
  overflow: auto;
  font-size: 14px;

  background-color: rgba(251, 251, 251, 1);
  border-color: transparent;
  outline: 0;
  border: none;

  &:focus,
  &:focus-visible {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    color: rgba(125, 132, 152, 0.4) !important; // override !important from input.sass
  }
`
