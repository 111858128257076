import { styled } from "@linaria/react"

import { BREAKPOINTS } from "src/styles/sizes"
import {
  titleColor,
  hoverBackgroundColor,
  activeBackgroundColor
} from "src/features/Sidebar/styles"

import Icon from "./Icon"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 10px 12px 12px;

  @media (max-width: calc(${BREAKPOINTS.semiLarge} - 1px)) {
    display: none;
  }
`

export const Text = styled.div`
  color: ${titleColor};

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`

export const Link = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: inherit;

  &:hover {
    color: white;

    ${Text} {
      color: white;
    }

    ${Icon} {
      background-color: ${hoverBackgroundColor};
      color: white;
    }
  }

  &.highlighted {
    ${Text} {
      color: white;
    }

    ${Icon} {
      background-color: ${activeBackgroundColor};
    }
  }
`
