import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store"

const useExpanded = (threadItem) => {
  const { expandedThreadItems } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const expanded = !!expandedThreadItems[threadItem.id]

  const expand = () => {
    dispatch(actions.expandThreadItem(threadItem.id))
  }

  const collapse = () => {
    dispatch(actions.collapseThreadItem(threadItem.id))
  }

  return { expanded, expand, collapse }
}

export default useExpanded
