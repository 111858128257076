import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { bool, shape, number, oneOf, string } from "prop-types"

import { getMeetingPackLastLocation } from "src/helpers/meetingPacks"
import { SuggestionItem } from "src/features/Sidebar/Suggestions/styles"

import { hasDraft } from "./helpers"
import { DraftLabel, Title } from "./styles"

const Thread = ({
  current,
  thread: { id, title, badge, path, active, __typename: typename }
}) => {
  const [showDraft, setShowDraft] = useState(hasDraft({ typename, id }) && !badge)

  useEffect(() => {
    const onAutoSaveChange = () => setShowDraft(hasDraft({ typename, id }) && !badge)
    window.addEventListener("autosaveChange", onAutoSaveChange)

    return () => {
      window.removeEventListener("autosaveChange", onAutoSaveChange)
    }
  })

  const threadPath =
    typename === "MeetingPackThread" ? getMeetingPackLastLocation(id) || path : path

  return (
    <SuggestionItem
      key={threadPath}
      href={`${threadPath}?focus=recent`}
      data-testid={`recent-thread-${id}`}
      data-behavior="recent-thread"
      className={classNames("menu-link", badge, { current, active })}
    >
      <Title>{title}</Title>
      {showDraft && <DraftLabel>[Draft]</DraftLabel>}
    </SuggestionItem>
  )
}

Thread.propTypes = {
  thread: shape({
    id: number.isRequired,
    title: string.isRequired,
    path: string.isRequired,
    badge: oneOf(["", "unread", "mention"]),
    active: bool
  }).isRequired,
  current: bool.isRequired
}

export default Thread
