import { styled } from "@linaria/react"
import { lighten } from "polished"

import { TEXT_COLORS } from "src/styles/colors"
import { FONT_SIZES, SPACES } from "src/styles/sizes"

const lightenTextMutedColor = lighten(0.25, TEXT_COLORS.muted)

export const Header = styled.div`
  margin-top: ${SPACES.xSmall};
`

export const Details = styled.div`
  font-size: ${FONT_SIZES.small};
  color: ${TEXT_COLORS.muted};
  margin: ${SPACES.xSmall} 0;
`

export const Dropzone = styled.div`
  border: 1px ${TEXT_COLORS.muted} dashed;
  border-radius: 2px;
  color: ${TEXT_COLORS.muted};
  text-align: center;

  .sub {
    color: ${lightenTextMutedColor};
  }

  &.is-active {
    padding: ${SPACES.semiLarge} 0;
    cursor: pointer;

    &.is-drag-active,
    &:hover {
      border-color: ${lightenTextMutedColor};
      color: ${lightenTextMutedColor};

      .sub {
        color: ${lighten(0.35, TEXT_COLORS.muted)};
      }
    }
  }

  &.is-uploading {
    cursor: not-allowed;
    padding: ${SPACES.medium} 0;
  }
`

export const Preview = styled.img`
  max-width: 100%;
`

export const DeleteButtonContainer = styled.div`
  text-align: right;
`

export const DeleteButton = styled.button`
  border: none;
  background: none;
  color: ${TEXT_COLORS.link};
  margin: ${SPACES.xSmall} 0;

  &:focus {
    box-shadow: none;
  }
`

export const ErrorMessage = styled.div`
  font-size: ${FONT_SIZES.small};
  color: ${TEXT_COLORS.danger};
  margin: ${SPACES.xSmall} 0;
`
