import getBasicConfiguration from "src/features/meetingPacks/MeetingPack/View/ContentContainer/Content/Document/getBasicConfiguration"
import { isHomeMeetings, isMeetingPackCreate } from "src/helpers/routes"
import { getCurrentUser } from "src/helpers/user"
import { be } from "src/helpers/document"

const path = window.location.pathname

window.addEventListener("load", () => {
  if (!window.PSPDFKit) {
    return
  }

  if (!Object.isFrozen(window.PSPDFKit.Options))
    window.PSPDFKit.Options.IGNORE_DOCUMENT_PERMISSIONS = true

  window.PSPDFKit.I18n.preloadLocalizationData("en-GB")
  window.PSPDFKit.I18n.preloadLocalizationData("en")
  window.PSPDFKit.I18n.preloadLocalizationData("fr")

  if (
    getCurrentUser() &&
    (isMeetingPackCreate(path) || isHomeMeetings(path) || be("meeting-pack-menu-link"))
  ) {
    // eslint-disable-next-line no-unused-expressions
    window.PSPDFKit.preloadWorker(getBasicConfiguration()).catch(console.error)
  }
})
