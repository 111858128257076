import { styled } from "@linaria/react"
import React from "react"

import { withVariations } from "src/helpers/linaria"
import BaseIconDone, { ICON_WIDTH } from "src/styles/components/IconDone"
import { BASE_COLORS } from "src/styles/colors"

export const IconDone = styled(withVariations(BaseIconDone, "status"))`
  position: absolute;
  right: calc(${ICON_WIDTH} / -2);
  top: calc(${ICON_WIDTH} / -2);

  &--status-syncing {
    background-color: ${BASE_COLORS.lightOrange};
  }
`

export const IconSyncing = ({ ...props }) => {
  return <IconDone type="cloud-empty" status="syncing" {...props} />
}
