import React, { useCallback } from "react"
import { useMutation } from "@apollo/client"
import { shape, bool, number, func } from "prop-types"

import createStarredItemMutation from "./createStarredItemMutation.gql"
import deleteStarredItemMutation from "./deleteStarredItemMutation.gql"
import { Icon } from "./styles"

const StarIcon = ({ account, setFocusAccountAfterUpdate }) => {
  const [createStarredItem, { loading: createStarredItemLoading }] = useMutation(
    createStarredItemMutation
  )
  const [deleteStarredItem, { loading: deleteStarredItemLoading }] = useMutation(
    deleteStarredItemMutation
  )

  const onClick = useCallback(() => {
    if (createStarredItemLoading || deleteStarredItemLoading) return

    const action = account.starred ? deleteStarredItem : createStarredItem

    setFocusAccountAfterUpdate(account.id)
    action({ variables: { resourceType: "Account", resourceId: account.id } })
  }, [account.id, account.starred])

  return (
    <Icon
      type={account.starred ? "star-filled-v2" : "star-hollow-v2"}
      className={account.starred ? "selected" : null}
      onClick={onClick}
      data-testid="sidebar-organization-star"
    />
  )
}

StarIcon.propTypes = {
  account: shape({
    id: number.isRequired,
    starred: bool.isRequired
  }).isRequired,
  setFocusAccountAfterUpdate: func.isRequired
}

export default StarIcon
