import React from "react"
import { bool, string } from "prop-types"
import { cx } from "@linaria/core"

import { Container, Extension } from "./styles"

const fileIconClassByExtension = (extension) => {
  if (!extension) return "default"

  const ext = extension.toLowerCase()

  if (["pdf"].includes(ext)) {
    return "pdf"
  }
  if (["doc", "docx"].includes(ext)) {
    return "doc"
  }
  if (["xls", "xlsx"].includes(ext)) {
    return "spreadsheet"
  }
  if (["csv", "text", "txt"].includes(ext)) {
    return "text"
  }
  if (["jpg", "jpeg", "png", "gif", "bmp", "tif", "tiff"].includes(ext)) {
    return "image"
  }
  if (["mp3", "flac", "wav"].includes(ext)) {
    return "audio"
  }
  if (["avi", "mov", "mpg", "mpeg"].includes(ext)) {
    return "video"
  }
  return "default"
}

const FileIcon = ({ extension, className, showExtension = true }) => {
  const iconClass = fileIconClassByExtension(extension)

  return (
    <Container className={cx(iconClass, className)} data-testid="file-icon">
      {showExtension && extension && <Extension>{extension.substring(0, 4)}</Extension>}
    </Container>
  )
}

FileIcon.propTypes = {
  extension: string,
  showExtension: bool
}

export default FileIcon
