// eslint-disable-next-line import/no-unresolved
import summariseIcon from "images/ai/summarise.svg?raw"
// eslint-disable-next-line import/no-unresolved
import queryIcon from "images/ai/query.svg?raw"

import { showFlashMessage } from "src/helpers/flash"
import { BOX_UPLOAD_URLS, BOX_API_HOST } from "src/constants"
import useDownloadFileLink from "src/hooks/useDownloadFileLink"

const boxUploadPath = "/box/upload"

export const rewriteUploadInterceptor = (config) => {
  // Box's uploder.js tries to change url to make chunked upload
  // since our proxy returns urls without host, uploder.js does it incorrectly
  if (config.parsedUrl?.host === "upload") {
    config.url = `${boxUploadPath}${config.parsedUrl?.pathname}`
  }

  return config
}

export const rewriteSessionEndpoints = (response) => {
  // If HTTPS protocol is used Box tries to make chunked upload
  // it makes POST response to /box/upload/api/2.0/files/upload_sessions
  // it returns "session_endpoints", which point endpoints to control upload
  // these endpoint have to be changed to point to our box proxy.
  // Doing this on backend, Heroku responds with 503 "Server Request Interrupted"
  // That's why we are modifying this endpoints on frontend
  // eslint-disable-next-line camelcase
  if (response.data?.session_endpoints) {
    const keys = ["abort", "commit", "list_parts", "log_event", "status", "upload_part"]

    keys.forEach((key) => {
      const url = response.data.session_endpoints[key]

      if (url) {
        response.data.session_endpoints[key] = BOX_UPLOAD_URLS.reduce(
          (result, currentValue) => result.replace(currentValue, boxUploadPath),
          url
        )
      }
    })
  }

  if (
    response.status === 403 &&
    response.message ===
      "This folder cannot be moved or deleted because it has been locked to prevent such operations."
  ) {
    showFlashMessage(
      "danger",
      "This folder cannot be moved or deleted because it is required for discussion attachments."
    )
  }

  return response
}

export const renameChooseButton = (text) => {
  $(".bcp-footer-actions .btn-primary").attr("data-content", text)
  $(".bcp-footer-actions").on("DOMSubtreeModified", () => {
    $(".bcp-footer-actions .btn-primary").attr("data-content", text)
  })
}

export const disableChooseButton = (text) => {
  const chooseButton = $(".bcp-footer-actions .btn-primary")
  chooseButton.addClass("is-disabled")
  chooseButton.attr("aria-disabled", "true")
  renameChooseButton(text)
}

export const enableChooseButton = (text) => {
  const chooseButton = $(".bcp-footer-actions .btn-primary")
  chooseButton.removeClass("is-disabled")
  chooseButton.attr("aria-disabled", "false")
  renameChooseButton(text)
}

export const isAvailable = () => {
  try {
    return Boolean(window.Box)
  } catch {
    return false
  }
}

export const buildDownloadButton = () => {
  const button = document.createElement("button")
  const icon = document.createElement("span")
  button.className = "bp-btn-plain download-box-file p-1"
  button.type = "button"
  icon.className = "icon-download"
  icon.title = "Download"
  button.appendChild(icon)

  const onClick =
    ({ boxAuthService, fileId, filename }) =>
    async () => {
      const authToken = await boxAuthService.fetchToken()
      const response = await fetch(
        `${BOX_API_HOST}/2.0/files/${fileId}?fields=download_url`,
        {
          headers: { authorization: `Bearer ${authToken}` }
        }
      )
      const data = await response.json()
      const { handleClick } = useDownloadFileLink({
        href: data.download_url,
        fileName: filename,
        onClick: () => {
          const link = document.createElement("a")
          link.href = data.download_url
          link.download = filename
          link.click()
        }
      })

      handleClick()
    }

  return { button, onClick }
}

const buildButton = ({ icon, title }) => {
  const button = document.createElement("button")
  button.className = "bp-btn-plain box-ai-button"
  button.type = "button"
  button.title = title
  button.insertAdjacentHTML("beforeend", icon)

  return { element: button, align: "left" }
}

export const buildOpenaiButtons = () => {
  const summariseButton = buildButton({
    icon: summariseIcon,
    title: "Summarise"
  })

  const askQuestionButton = buildButton({
    icon: queryIcon,
    title: "Query"
  })

  return {
    summariseButton,
    askQuestionButton
  }
}
