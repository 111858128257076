import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import { string } from "prop-types"
import { DirectUpload } from "activestorage"
import { cx } from "@linaria/core"

import ContentLoader from "src/styles/components/ContentLoader"

import {
  Header,
  Details,
  Dropzone,
  Preview,
  DeleteButton,
  DeleteButtonContainer,
  ErrorMessage
} from "./styles"

const MAX_WIDTH = 350
const MAX_HEIGHT = 152
const MAX_SIZE_KB = 100
const MAX_SIZE = MAX_SIZE_KB * 1024
const GENERIC_ERROR_MESSAGE =
  "Failed to upload. Please try again or contact support@knowa.co"
const LIMIT_ERROR_MESSAGE = "Oops, looks like that image is too big."

const ALLOWED_MIME_TYPES_IMAGES = {
  "image/png": [".png"],
  "image/jpeg": [".jpg", ".jpeg"]
}

const LogoUploadZone = ({ logoSignedId, logoPreviewUrl, directUploadUrl }) => {
  const [signedId, setSignedId] = useState(logoSignedId)
  const [previewUrl, setPreviewUrl] = useState(logoPreviewUrl)
  const [errorMessage, setErrorMessage] = useState()

  useEffect(() => {
    return () => URL.revokeObjectURL(previewUrl)
  }, [previewUrl])

  const deleteLogo = (event) => {
    event.preventDefault()

    setSignedId(null)
    setPreviewUrl(null)
    setErrorMessage(null)
  }

  const onDrop = (files, rejections) => {
    if (rejections.length) {
      const error = rejections[0].errors[0]
      setErrorMessage(
        error.code === "file-too-large" ? LIMIT_ERROR_MESSAGE : GENERIC_ERROR_MESSAGE
      )
      return
    }

    setSignedId(null)
    setPreviewUrl(null)
    setErrorMessage(null)

    const file = files[0]
    const imageUrl = URL.createObjectURL(file)
    const image = new Image()

    image.addEventListener("load", () => {
      if (image.width <= MAX_WIDTH && image.height <= MAX_HEIGHT) {
        setPreviewUrl(imageUrl)

        const upload = new DirectUpload(file, directUploadUrl)

        upload.create((error, blob) => {
          if (error) {
            setPreviewUrl(null)
            setErrorMessage(GENERIC_ERROR_MESSAGE)
          } else {
            setSignedId(blob.signed_id)
          }
        })
      } else {
        setErrorMessage(LIMIT_ERROR_MESSAGE)
      }
    })

    image.src = imageUrl
  }

  const previewAvailable = !!previewUrl
  const uploadingMode = !signedId && previewAvailable
  const previewMode = signedId && previewAvailable

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ALLOWED_MIME_TYPES_IMAGES,
    disabled: previewAvailable,
    multiple: false,
    maxSize: MAX_SIZE,
    onDrop
  })

  const dropZoneClassNames = cx(
    !previewMode && "is-active",
    isDragActive && "is-drag-active",
    uploadingMode && "is-uploading"
  )

  return (
    <>
      <Header>PDF export cover page logo</Header>
      <Details>
        The image file should be in PNG or JPG format, up to {MAX_WIDTH} x {MAX_HEIGHT}{" "}
        pixels and no bigger than {MAX_SIZE_KB}KB.
      </Details>

      <Dropzone className={dropZoneClassNames}>
        {!uploadingMode && !previewMode && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            <div>Drop image here, or click to select image from your computer</div>
            <div className="sub">Supported formats png, jpeg, jpg.</div>
          </div>
        )}

        {uploadingMode && <ContentLoader />}

        {previewMode && (
          <Preview
            src={previewUrl}
            onLoad={() => {
              URL.revokeObjectURL(previewUrl)
            }}
            alt="Meeting pack logo preview"
          />
        )}
      </Dropzone>

      <input type="hidden" name="meeting_pack[logo]" value={signedId || ""} />

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {previewMode && (
        <DeleteButtonContainer>
          <DeleteButton type="button" onClick={deleteLogo}>
            Delete
          </DeleteButton>
        </DeleteButtonContainer>
      )}
    </>
  )
}

LogoUploadZone.propTypes = {
  logoSignedId: string,
  logoPreviewUrl: string,
  directUploadUrl: string.isRequired
}

export default LogoUploadZone
