import React, { useCallback, useState } from "react"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { bool, number, shape, string } from "prop-types"

import SyncStatus from "src/features/Home/Meetings/SyncStatus"
import { getMeetingPackLastLocation } from "src/helpers/meetingPacks"
import { MenuItem } from "src/features/Sidebar/styles"

const MeetingPack = ({ id, className, current, unread, path, name, syncStats }) => {
  const [isSavedOffline, setIsSavedOffline] = useState(false)

  const setAsSavedOffline = useCallback(() => setIsSavedOffline(true), [])
  const lastMeetingPackLocation = getMeetingPackLastLocation(id)

  return (
    <MenuItem
      key={id}
      className={classNames(className, { mention: unread, current })}
      data-testid="sidebar-meeting"
      data-id={id}
    >
      <a
        href={lastMeetingPackLocation || path}
        className={classNames("menu-link meeting-pack-menu-link", {
          "saved-offline": isSavedOffline
        })}
      >
        <span title={name}>{name}</span>
        <SyncStatus
          onSyncFinished={setAsSavedOffline}
          allDocumentsAreSaved={syncStats?.allDocumentsAreSaved}
          allDocumentsCount={syncStats?.allDocumentsCount}
          savedDocumentsCount={syncStats?.savedDocumentsCount}
        />
      </a>
    </MenuItem>
  )
}

MeetingPack.propTypes = {
  id: number.isRequired,
  path: string.isRequired,
  name: string.isRequired,
  unread: bool.isRequired,
  current: bool.isRequired,
  syncStats: shape({
    allDocumentsAreSaved: bool,
    savedDocumentsCount: number,
    allDocumentsCount: number
  })
}

export default styled(MeetingPack)`
  [class^="icon-"] {
    width: 18px;
    height: 18px;
    font-size: 0.6rem;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    top: 6px;
    right: 28px;
    margin-left: 3px;
    padding-top: 5px;
    text-align: center;
  }
`
