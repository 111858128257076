import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"
import {
  hoverBackgroundColor,
  titleColor,
  rowSpace,
  borderColor
} from "src/features/Sidebar/styles"

export const Divider = styled.div`
  margin: 10px 0;
  height: 1px;
  background: ${borderColor};
`

export const AddMeetingPackLink = styled.a`
  display: flex;
  align-items: center;
  padding: 6px 8px;

  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;

  ${Icon} {
    margin-right: 4px;
  }

  &:hover {
    border-radius: 6px;
    background: ${hoverBackgroundColor};
    color: white;
  }
`

export const SubSectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${rowSpace} 0;

  color: ${titleColor};
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.with-padding {
    padding: 6px 0;
  }
`
