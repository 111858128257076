import { styled } from "@linaria/react"

import { BASE_TEXT_COLOR } from "src/features/UniversalAi/Sidebar/styles"

import { BORDER, BORDER_COLOR, BORDER_RADIUS, PADDING, PADDING_X } from "../styles"

export const Menu = styled.div`
  border: ${BORDER};
  border-radius: ${BORDER_RADIUS};
  position: absolute;
  left: 0;
  top: calc(100% + 8px);
  width: 100%;
  box-shadow: 0px 2px 5px 0px #73839e26;
  background-color: white;
  z-index: 4;
`

export const SearchInput = styled.input``

export const SearchContainer = styled.div`
  padding: ${PADDING};
  border-bottom: ${BORDER};

  // Nesting to increase specificity
  & ${SearchInput} {
    height: 35px;
    width: 100%;
    border: ${BORDER};
    border-radius: ${BORDER_RADIUS};
    color: ${BASE_TEXT_COLOR} !important; // "important" to override "important" defined in input.sass
  }

  & ${SearchInput}:focus-visible {
    outline: none;
  }
`

export const Divider = styled.div`
  height: 1px;
  background-size: calc(100% - 2 * ${PADDING_X}) 1px;
  background-position: bottom 0px left ${PADDING_X};
  background-repeat: no-repeat;
  background-image: linear-gradient(${BORDER_COLOR}, ${BORDER_COLOR});
`

export const Message = styled.div`
  padding: 8px ${PADDING_X};
`
