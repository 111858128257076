import React from "react"
import { arrayOf, number, shape, string } from "prop-types"
import classNames from "classnames"

import useBaseNavData from "src/features/Sidebar/BaseNav/useBaseNavData"

import { Container, Link, Text } from "./styles"
import Icon from "./Icon"

const BaseNav = ({ exitPath, unreadItems, starredItems, meetingPacks }) => {
  const {
    meetingPacksCount,
    unreadMentionsCount,
    pendingActionsCount,
    starredItemsCount,
    unreadChatsCount,
    isOnTheMeetingsPage,
    isOnTheMentionsPage,
    isOnTheStarredItemsPage,
    isOnTheChatsPage
  } = useBaseNavData({ unreadItems, starredItems, meetingPacks })

  return (
    <Container className="sidebar-section sidebar-base-nav">
      {!!meetingPacksCount && (
        <Link
          href={isOnTheMeetingsPage ? exitPath : "/meetings"}
          className={classNames("sidebar-base-nav-link saved-offline", {
            highlighted: isOnTheMeetingsPage
          })}
          data-testid="meetings-navbar-link"
        >
          <Icon type="calendar" unreadAndMention={!!unreadItems.length} />
          <Text>Meetings</Text>
        </Link>
      )}
      <Link
        href={isOnTheMentionsPage ? exitPath : "/mentions"}
        className={classNames("sidebar-base-nav-link", {
          highlighted: isOnTheMentionsPage
        })}
        data-testid="mentions-navbar-link"
      >
        <Icon
          type="mention-v2"
          unreadAndMention={!!(unreadMentionsCount || pendingActionsCount)}
        />
        <Text>Mentions</Text>
      </Link>
      <Link
        href={isOnTheStarredItemsPage ? exitPath : "/starred_items"}
        className={classNames("sidebar-base-nav-link", {
          highlighted: isOnTheStarredItemsPage
        })}
        data-testid="starred-navbar-link"
      >
        <Icon type={starredItemsCount ? "star-filled-v2" : "star-hollow-v2"} />
        <Text>Starred</Text>
      </Link>
      <Link
        href={isOnTheChatsPage ? exitPath : "/chats"}
        className={classNames("sidebar-base-nav-link", {
          highlighted: isOnTheChatsPage
        })}
        data-testid="chats-navbar-link"
      >
        <Icon type="message-v2" unread={!!unreadChatsCount} />
        <Text>Chats</Text>
      </Link>
    </Container>
  )
}

BaseNav.propTypes = {
  exitPath: string.isRequired,
  unreadItems: arrayOf(
    shape({
      id: number
    })
  ),
  starredItems: arrayOf(
    shape({
      id: number
    })
  ),
  meetingPacks: arrayOf(
    shape({
      id: number
    })
  )
}

export default BaseNav
