import React, { useCallback, useRef, useState, useMemo } from "react"
import { arrayOf, number, shape } from "prop-types"
import { Form, InputGroup } from "react-bootstrap"
import classNames from "classnames"
import { useDebounce } from "use-debounce"

import Icon from "src/styles/components/Icon"
import useInputFocus from "src/hooks/forms/useInputFocus"
import useListKeyboardNavigation from "src/hooks/forms/useListKeyboardNavigation"
import { goTo } from "src/helpers/url"
import { KEY_CODES } from "src/constants"

import { Container, SuggestionInputGroup } from "./styles"
import useSuggestions from "./useSuggestions"
import JumpToPanel from "./JumpToPanel"
import RecentThreads from "./RecentThreads"
import { MINIMUM_QUERY_LENGTH } from "./useSuggestions/constants"

const Suggestions = ({ recentThreads }) => {
  const [jumpToQuery, setJumpToQuery] = useState("")
  const [debouncedJumpToQuery] = useDebounce(jumpToQuery, 300)
  const setJumpToQueryOnChange = (e) => setJumpToQuery(e.target.value)
  const { suggestions } = useSuggestions(debouncedJumpToQuery)

  const showJumpToPanel = useMemo(
    () =>
      jumpToQuery && jumpToQuery.length >= MINIMUM_QUERY_LENGTH && !!suggestions.length,
    [jumpToQuery?.length, suggestions.length]
  )

  const { itemsWithActiveFlag, activeItem, handleKeyboardNavigation } =
    useListKeyboardNavigation({
      items: showJumpToPanel ? suggestions : recentThreads,
      firstItemActive: false
    })

  const { isInputFocused, setIsFocused, setIsBlurred } = useInputFocus(false)
  const inputRef = useRef()

  const handleEnter = useCallback(
    (e) => {
      if (e.charCode !== KEY_CODES.enter) return

      inputRef.current.blur()

      if (activeItem) {
        e.preventDefault()
        goTo(activeItem.path)
      }
    },
    [inputRef.current, activeItem?.path]
  )

  const handleClear = useCallback(() => {
    inputRef.current.blur()
    setJumpToQuery("")
  }, [inputRef.current])

  return (
    <Container>
      <InputGroup
        as={SuggestionInputGroup}
        className={classNames({ focused: isInputFocused })}
      >
        <Form.Control
          ref={inputRef}
          value={jumpToQuery || ""}
          placeholder={isInputFocused ? "" : "Recent / Jump to..."}
          onChange={setJumpToQueryOnChange}
          onFocus={setIsFocused}
          onBlur={setIsBlurred}
          onKeyPress={handleEnter}
          onKeyDown={handleKeyboardNavigation}
          data-testid="recent-jump-to-input"
        />
        <InputGroup.Append className="align-items-center">
          {isInputFocused ? (
            <Icon type="cancel" onClick={handleClear} size="medium" />
          ) : (
            <Icon type="arrow-right-top" onClick={() => inputRef.current.focus()} />
          )}
        </InputGroup.Append>
      </InputGroup>

      {isInputFocused && !showJumpToPanel && !!recentThreads.length && (
        <RecentThreads recentThreads={itemsWithActiveFlag} />
      )}

      {isInputFocused && showJumpToPanel && (
        <JumpToPanel suggestions={itemsWithActiveFlag} />
      )}
    </Container>
  )
}

Suggestions.propTypes = {
  recentThreads: arrayOf(
    shape({
      id: number.isRequired
    })
  )
}

export default Suggestions
