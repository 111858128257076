import { styled } from "@linaria/react"

export const titleColor = "#AFAFC8"
export const hoverBackgroundColor = "#46404f"
export const activeBackgroundColor = "#747487"
export const linkColor = "#E7E7F1"
export const rowSpace = "4px"
export const navBackgroundColor = "#20182C"
export const borderColor = "#8B8B9C"

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #181023;
`

export const SectionTitle = styled.div`
  color: ${titleColor};

  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  padding-bottom: 6px;
`

export const MenuItem = styled.div`
  margin: 0 0 ${rowSpace};

  &.current {
    border-radius: 6px;
    background: ${activeBackgroundColor};
  }

  &:hover:not(.current) {
    border-radius: 6px;
    background: ${hoverBackgroundColor};
  }

  > a {
    position: relative;
    display: block;
    padding: 6px 8px;

    color: ${linkColor};

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
