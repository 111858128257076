import { styled } from "@linaria/react"

import { BASE_TEXT_COLOR } from "src/features/UniversalAi/Sidebar/styles"
import FileIcon from "src/styles/components/FileIcon"

export const Container = styled.div`
  background-color: #fbfbfb;
  border-radius: 8px;
  padding: 18px 20px;
  width: 361px;
  box-shadow: 0px 4px 6px -1px #0000001a;
  z-index: 3;
  color: ${BASE_TEXT_COLOR};
`

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-family: "Space Grotesk";
  line-height: 20px;
  display: flex;
  gap: 5px;
  padding-bottom: 10px;
`

export const Icon = styled(FileIcon)`
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  margin-bottom: 0;
  margin-right: 0;
  background-size: cover;
  background-position-x: -3px;
`

export const SourcePages = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 5px;
`

export const Excerpt = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

export const ExcerptText = styled.div`
  margin-bottom: 10px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const ExcerptDivider = styled.div`
  height: 1px;
  border-bottom: 1px solid #0000000d;
  margin-bottom: 10px;
`

export const Breadcrumbs = styled.div`
  font-size: 11px;
  font-weight: 500;
`
