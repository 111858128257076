import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import Icon from "src/styles/components/Icon"

export const Container = styled.div`
  border-top: 1px solid #f4f4f5;
  border-bottom: 1px solid #f4f4f5;
`

export const QueryContainer = styled.div`
  padding: 12px 16px;

  &:hover {
    text-decoration: underline;
    border-color: #e9e9ea;
    cursor: pointer;
    box-shadow: 0px 2px 5px 0px #73839e26;
  }
`

export const ThreadItemContainer = styled.div`
  padding: 12px 16px;
`
export const CloseButton = styled(UnstyledButton)`
  margin-top: 16px;
  text-align: center;
  width: 100%;
  display: inline-block;
`

export const CloseIcon = styled(Icon)`
  display: inline-block;
  rotate: -90deg;
  font-size: 12px;
`
