import { styled } from "@linaria/react"

export const ExpandableContentChildren = styled.div`
  min-height: 0;
  transition: visibility 1s;
  visibility: hidden;
`

export const ExpandableContentContainer = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows ${(props) => props.transitionDuration};

  &.expanded {
    grid-template-rows: 1fr;
  }

  &.expanded ${ExpandableContentChildren} {
    visibility: visible;
  }
`
