import { useQuery } from "@apollo/client"

import searchQuery from "./searchQuery.gql"

const MIN_QUERY_LIMIT = 3

const useSearch = (query) => {
  const { data, loading } = useQuery(searchQuery, {
    variables: { query },
    skip: !query || query.length < MIN_QUERY_LIMIT,
    fetchPolicy: "network-only"
  })

  const searchResults = data?.aiAreaOfFocusSearch

  return { searchResults, loading }
}

export default useSearch
