import { useQuery } from "@apollo/client"

import suggestionsQuery from "./suggestionsQuery.gql"
import useCurrentPageFocusableItem from "./useCurrentPageFocusableItem"

const areEqual = (focusableItemA, focusableItemB) => {
  return (
    focusableItemA?.type === focusableItemB?.type &&
    focusableItemA?.id === focusableItemB?.id
  )
}

const useSuggestions = (focusedItem) => {
  const focusedItemInput = { id: focusedItem?.id, type: focusedItem?.type }

  const { data, loading } = useQuery(suggestionsQuery, {
    variables: { focusedItem: focusedItemInput },
    fetchPolicy: "cache-first",
    skip: !focusedItem
  })

  const currentPageFocusableItem = useCurrentPageFocusableItem()

  const suggestionsFromQuery = data?.aiAreaOfFocusSuggestions || []

  const hasCurrentPageFocusableAsSuggestion = suggestionsFromQuery.some((suggestion) =>
    areEqual(suggestion, currentPageFocusableItem)
  )

  const isCurrentPageFocusableItemSuggested =
    currentPageFocusableItem &&
    !hasCurrentPageFocusableAsSuggestion &&
    !areEqual(focusedItem, currentPageFocusableItem)

  const suggestions = isCurrentPageFocusableItemSuggested
    ? [currentPageFocusableItem, ...suggestionsFromQuery]
    : suggestionsFromQuery

  return { suggestions, loading }
}

export default useSuggestions
