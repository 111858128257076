import { styled } from "@linaria/react"

export const Container = styled.div`
  position: relative;
  overflow-y: auto;
`

export const ScrollableContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  /* Hide the scrollbar for WebKit browsers (Chrome, Safari, newer versions of Opera) */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide the scrollbar for IE, Edge, and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const ShadowOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  background: linear-gradient(to bottom, #73839e26, #73839e0d 60%, transparent 100%);
  pointer-events: none;
  opacity: 0;
  z-index: 3;

  &.visible {
    opacity: 1;
  }
`
