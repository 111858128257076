import { styled } from "@linaria/react"

import BaseIcon from "src/styles/components/Icon"

export const Icon = styled(BaseIcon)`
  display: none;
  font-size: 0.9rem;

  &.selected {
    display: inline-block;
  }
`
