import { useCallback, useRef } from "react"

const useScrollToTop = () => {
  const ref = useRef(null)

  const scrollToTop = useCallback(() => {
    if (!ref.current) return

    ref.current.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, [!!ref.current])

  return { ref, scrollToTop }
}

export default useScrollToTop
