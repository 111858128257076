import React from "react"

import { ThreadItem as ThreadItemPropType } from "src/features/UniversalAi/Sidebar/propTypes"
import ThreadItem from "src/features/UniversalAi/Sidebar/ThreadItem"
import ExpandableContent from "src/components/ExpandableContent"
import Query from "src/features/UniversalAi/Sidebar/Query"

import {
  CloseButton,
  CloseIcon,
  Container,
  QueryContainer,
  ThreadItemContainer
} from "./styles"
import useExpanded from "./useExpanded"

const ExpandableThreadItem = ({ threadItem }) => {
  const { expanded, expand, collapse } = useExpanded(threadItem)

  return (
    <Container>
      <ExpandableContent expanded={!expanded}>
        <QueryContainer onClick={expand}>
          <Query
            query={threadItem.query}
            queryType={threadItem.queryType}
            focusableTitle={threadItem.focusable.title}
          />
        </QueryContainer>
      </ExpandableContent>

      <ExpandableContent expanded={expanded}>
        <ThreadItemContainer>
          <ThreadItem threadItem={threadItem} />

          <CloseButton onClick={collapse}>
            <CloseIcon type="navigate-next" />
          </CloseButton>
        </ThreadItemContainer>
      </ExpandableContent>
    </Container>
  )
}

ExpandableThreadItem.propTypes = {
  threadItem: ThreadItemPropType.isRequired
}

export default ExpandableThreadItem
