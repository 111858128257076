import React from "react"

import { toggleSidebar } from "src/helpers/document"
import Icon from "src/styles/components/Icon"

import { SidebarToggleButton } from "./styles"

const Toggle = () => {
  return (
    <SidebarToggleButton
      className="sidebar-toggle saved-offline show-sidebar"
      onClick={toggleSidebar}
    >
      <Icon type="arrow-left-circled" />
    </SidebarToggleButton>
  )
}

Toggle.propTypes = {}

export default Toggle
