import React from "react"
import { bool, number, func } from "prop-types"
import { Modal } from "react-bootstrap"

import successImage from "images/meeting-pack-successfully-published.svg"
import WarningText from "src/styles/components/WarningText"

import { SuccessImage, TextContainer, ModalFooter } from "./styles"

const SuccessModal = ({ isOpened, onClose, meetingPackId }) => {
  return (
    <Modal centered size="md" onHide={onClose} show={isOpened}>
      <Modal.Header closeButton>
        <Modal.Title>Successfully published</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <SuccessImage height={150} src={successImage} />

        <TextContainer>
          <WarningText text="Don't forget to record your meeting if you want to use Knowa Verse." />
        </TextContainer>
        <TextContainer>
          <WarningText text="Here are some " />
          <WarningText
            component="a"
            href="//help.knowa.co/en/articles/9155437-how-to-record-your-meeting-for-knowa-verse"
            target="_blank"
            rel="noreferrer"
            text="helpful recording tips."
          />
        </TextContainer>
      </Modal.Body>
      <ModalFooter>
        <div>
          <div className="d-flex justify-content-center">
            <button className="button is-secondary" onClick={onClose}>
              Close
            </button>
            <a className="button" href={`/meeting_packs/${meetingPackId}/view`}>
              View Meeting Pack
            </a>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

SuccessModal.propTypes = {
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  meetingPackId: number.isRequired
}

export default SuccessModal
