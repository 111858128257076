import React, { useCallback, useContext, useEffect } from "react"
import { string } from "prop-types"
import { Spinner } from "react-bootstrap"
import classNames from "classnames"
import { useDispatch, useSelector } from "react-redux"

import MeetingPackOfflineContext from "src/features/meetingPacks/MeetingPack/View/MeetingPackOfflineContext"
import { DOCUMENT_LOADING_STATES } from "src/features/meetingPacks/MeetingPack/constants"
import { KEY_CODES } from "src/constants"
import buildAiButtons from "src/components/pspsdfkitButtons/AiButtons/build"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"
import {
  getActiveDocument,
  getCurrentUserId,
  getPage
} from "src/features/meetingPacks/MeetingPack/selectors"
import { goBack, goForward } from "src/features/meetingPacks/MeetingPack/meetingPackSlice"

import usePSPDFKit from "./usePSPDFKit"
import { DocumentEditorContainer, Loading } from "./styles"

const Document = ({ highlighted }) => {
  const dispatch = useDispatch()

  const userId = useSelector(getCurrentUserId)
  const document = useSelector(getActiveDocument)
  const page = useSelector(getPage)

  const { isSavedOffline } = useContext(MeetingPackOfflineContext)

  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()

  const summariseDocumentHandler = () =>
    summariseAsTextInSidebar({
      id: document.id,
      type: FocusableItemType.AgendaItemDocument
    })

  const queryDocumentHandler = () =>
    queryInSidebar({
      id: document.id,
      type: FocusableItemType.AgendaItemDocument
    })

  const customToolbarButtons = buildAiButtons({
    summariseDocumentHandler,
    queryDocumentHandler,
    documentContentExtracted: document.contentExtracted
  })

  const { containerRef, loadingState } = usePSPDFKit({
    userId,
    page,
    highlighted,
    documentId: document.id,
    useCache: isSavedOffline,
    container: "#document-preview",
    customToolbarButtons
  })

  const handleKeyDown = useCallback((event) => {
    if (event.target.nodeName !== "BODY") return

    switch (event.keyCode) {
      case KEY_CODES.leftArrow:
        dispatch(goBack())
        break
      case KEY_CODES.rightArrow:
        dispatch(goForward())
        break
      default:
    }
  }, [])

  useEffect(() => {
    if (containerRef.current && loadingState === DOCUMENT_LOADING_STATES.loaded) {
      const iframeDocument = $(containerRef.current).find("iframe")[0].contentWindow
        .document
      iframeDocument.addEventListener("keydown", handleKeyDown)

      return () => iframeDocument.removeEventListener("keydown", handleKeyDown)
    }
  }, [containerRef, loadingState])

  return (
    <div>
      {loadingState === DOCUMENT_LOADING_STATES.isLoading && (
        <Loading>
          <Spinner animation="border" variant="secondary" />
        </Loading>
      )}

      <DocumentEditorContainer
        id="document-preview"
        className={classNames("document-container", {
          invisible: loadingState !== DOCUMENT_LOADING_STATES.loaded
        })}
        ref={containerRef}
      />
    </div>
  )
}

Document.propTypes = {
  highlighted: string
}

export default Document
