import React from "react"
import { bool, oneOf, string } from "prop-types"

import generatingImgUrl from "images/ai/generating.png"
import { FocusableItemType, FocusableItemTypes } from "src/features/UniversalAi/constants"

import { GeneratingImg, LoadingStateContainer } from "./styles"

const FOCUSABLE_TYPE_TO_LABEL = {
  [FocusableItemType.AttachedDocument]: "Document",
  [FocusableItemType.Discussion]: "Discussion",
  [FocusableItemType.Group]: "Group",
  [FocusableItemType.PrivateScheme]: "Space",
  [FocusableItemType.JointScheme]: "Space",
  [FocusableItemType.Account]: "Org",
  [FocusableItemType.MeetingPack]: "Meeting",
  [FocusableItemType.AgendaItem]: "Agenda item",
  [FocusableItemType.AgendaItemDocument]: "Meeting Document",
  [FocusableItemType.ContentCloudFile]: "Group Document",
  [FocusableItemType.AppsContent]: "App"
}

const COMPOUND_FOCUSABLE_TYPES = [
  FocusableItemType.Discussion,
  FocusableItemType.Group,
  FocusableItemType.PrivateScheme,
  FocusableItemType.JointScheme,
  FocusableItemType.Account,
  FocusableItemType.MeetingPack
]

const buildLoadingLabel = ({ focusableTitle, focusableType }) => {
  const action = COMPOUND_FOCUSABLE_TYPES.includes(focusableType)
    ? "Looking across"
    : "Reading"
  const typeLabel = FOCUSABLE_TYPE_TO_LABEL[focusableType]
  const typeLabelWithColon = typeLabel && `${typeLabel}:`

  return [action, typeLabelWithColon, focusableTitle].filter(Boolean).join(" ")
}

const buildGeneratingLabel = ({ focusableTitle, focusableType }) => {
  const typeLabel = FOCUSABLE_TYPE_TO_LABEL[focusableType]
  const typeLabelWithColon = typeLabel && `${typeLabel}:`

  return ["Generating response from", typeLabelWithColon, focusableTitle]
    .filter(Boolean)
    .join(" ")
}

const LoadingState = ({ focusableTitle, focusableType, loading, hasResponse }) => {
  if (hasResponse) return null

  return (
    <LoadingStateContainer>
      <GeneratingImg src={generatingImgUrl} alt="" />
      {loading && buildLoadingLabel({ focusableTitle, focusableType })}
      {!loading &&
        !hasResponse &&
        buildGeneratingLabel({ focusableTitle, focusableType })}
    </LoadingStateContainer>
  )
}

LoadingState.propTypes = {
  focusableTitle: string.isRequired,
  focusableType: oneOf(FocusableItemTypes),
  loading: bool,
  hasResponse: bool
}

export default LoadingState
