import { styled } from "@linaria/react"
import { lighten } from "polished"

import { TEXT_COLORS } from "src/styles/colors"

export const lightenTextMutedColor = lighten(0.25, TEXT_COLORS.muted)

export const DropZone = styled.div`
  border: 1px ${TEXT_COLORS.muted} dashed;
  border-radius: 2px;
  color: ${TEXT_COLORS.muted};
  cursor: pointer;
  padding: 6rem 0;
  text-align: center;
  transition: all 0.1s linear;

  &.is-drag-active,
  &:hover {
    border-color: ${lightenTextMutedColor};
    color: ${lightenTextMutedColor};
  }
`
