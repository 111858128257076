import React, { useCallback, useRef } from "react"
import { useMutation } from "@apollo/client"
import { arrayOf, oneOf, string, bool } from "prop-types"
import classNames from "classnames"

import { SUGGESTIONS } from "src/features/Search/constants"
import { SuggestionItem } from "src/features/Sidebar/Suggestions/styles"

import saveJumpToMutation from "./saveJumpTo.gql"
import { Title, Hint, TitleContainer } from "./styles"

const hintTypeNames = {
  [SUGGESTIONS.meeting]: "Meeting Pack",
  [SUGGESTIONS.jointScheme]: "Connected Space",
  [SUGGESTIONS.privateScheme]: "Space",
  [SUGGESTIONS.group]: "Group",
  [SUGGESTIONS.discussion]: "Discussion",
  [SUGGESTIONS.folder]: "Folder",
  [SUGGESTIONS.app]: "App"
}

const Suggestion = ({ type, title, path, parent, active }) => {
  const [saveJumpTo] = useMutation(saveJumpToMutation)
  const linkRef = useRef()

  const handleClick = useCallback(() => {
    saveJumpTo({ variables: { type, title, path, parent } })
  }, [type, title, path, parent])

  return (
    <SuggestionItem
      ref={linkRef}
      href={path}
      data-testid="suggestion-link"
      onClick={handleClick}
      className={classNames({ active })}
    >
      <TitleContainer>
        <Title>{title}</Title>
        {hintTypeNames[type] && (
          <Hint>
            {hintTypeNames[type]}
            {parent.map((parentName) => (
              <span key={parentName}>
                {" "}
                in <strong>{parentName}</strong>
              </span>
            ))}
          </Hint>
        )}
      </TitleContainer>
    </SuggestionItem>
  )
}

Suggestion.propTypes = {
  path: string.isRequired,
  title: string.isRequired,
  type: oneOf(Object.values(SUGGESTIONS)).isRequired,
  parent: arrayOf(string),
  active: bool
}

export default Suggestion
