import React from "react"
import { func } from "prop-types"

import { FocusableItem } from "src/features/UniversalAi/Sidebar/propTypes"

import { Button, Title, FocusableItemIcon } from "./styles"

const SectionItem = ({ item, className, onClick }) => {
  return (
    <Button className={className} onClick={onClick}>
      <FocusableItemIcon itemType={item.type} />
      <Title>{item.title}</Title>
    </Button>
  )
}

SectionItem.propTypes = {
  item: FocusableItem.isRequired,
  onClick: func
}

export default SectionItem
