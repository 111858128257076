import React from "react"
import { Spinner } from "react-bootstrap"
import { bool, shape, string } from "prop-types"

import { FileRowContainer, FileTitle } from "./styles"

const FileRow = ({ file, isUploading }) => {
  return (
    <FileRowContainer data-testid="file-row">
      <FileTitle>{file.name}</FileTitle>

      {isUploading && <Spinner animation="grow" size="sm" />}
    </FileRowContainer>
  )
}

FileRow.propTypes = {
  file: shape({
    name: string.isRequired
  }),
  isUploading: bool
}

export default FileRow
