import { useEffect, useState } from "react"
import { useInView } from "react-intersection-observer"

const usePagination = (rawEntities, setEntities, filters, page, setPage, loading) => {
  const [finishPagination, setFinishPagination] = useState(false)

  useEffect(() => {
    setEntities([])
    setPage(1)
    setFinishPagination(false)
  }, [JSON.stringify(filters)])

  const [refPaginationTrigger, inView] = useInView({
    onChange: () => {
      if (inView && !finishPagination) {
        setPage(page + 1)
      }
    }
  })

  useEffect(() => {
    if (loading || !rawEntities) return

    if (rawEntities.length) {
      setEntities((prevEntities) => [...prevEntities, ...rawEntities])
    } else {
      setFinishPagination(true)
    }
  }, [JSON.stringify(filters), page, loading])

  return {
    refPaginationTrigger
  }
}

export default usePagination
