import React from "react"
import { arrayOf, bool, number, shape, string, func } from "prop-types"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

import FilesList from "./FilesList"

const UploadedFilesList = ({ files, setFiles }) => {
  if (!files.length) return null

  const sortingEnabled = !!setFiles

  if (sortingEnabled) {
    return (
      <DndProvider backend={HTML5Backend}>
        <FilesList files={files} setFiles={setFiles} sortingEnabled />
      </DndProvider>
    )
  }

  return <FilesList files={files} />
}

UploadedFilesList.propTypes = {
  files: arrayOf(
    shape({
      file: shape({
        name: string.isRequired,
        size: number
      }),
      isUploading: bool
    })
  ).isRequired,
  setFiles: func
}

export default UploadedFilesList
