import { combineReducers } from "@reduxjs/toolkit"

import accounts from "src/resources/accounts/slice"
import agendaItem from "src/features/meetingPacks/AgendaItem/agendaItemSlice"
import discussions from "src/resources/discussions/slice"
import documentPreview from "src/features/DocumentPreview/store/slice"
import groups from "src/resources/groups/slice"
import meetingPack from "src/features/meetingPacks/MeetingPack/meetingPackSlice"
import meetingPacks from "src/resources/meetingPacks/slice"
import mentions from "src/resources/mentions/slice"
import schemes from "src/resources/schemes/slice"
import starredItems from "src/resources/starredItems/slice"
import tags from "src/resources/tags/slice"
import universalAi from "src/features/UniversalAi/store/slice"
import currentEntity from "src/resources/currentEntity/slice"

export default combineReducers({
  accounts,
  agendaItem,
  discussions,
  documentPreview,
  groups,
  meetingPack,
  meetingPacks,
  mentions,
  schemes,
  starredItems,
  tags,
  universalAi,
  currentEntity
})
