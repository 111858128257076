import React, { useEffect, useMemo, useState, useRef } from "react"
import { bool, number, shape, string } from "prop-types"
import classNames from "classnames"
import { styled } from "@linaria/react"
import { useQuery } from "@apollo/client"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

import { BREAKPOINTS } from "src/styles/sizes"

import Header from "../Header"

import Utterances from "./Utterances"
import AgendaItemsSidebar from "./AgendaItemsSidebar"
import transcriptQuery from "./transcript.gql"
import useUpdateTranscript from "./useUpdateTranscript"
import NoContent from "./NoContent"
import FixedAgendaItem from "./Utterances/FixedAgendaItem"
import ScrollContext from "./ScrollContext"
import { TRANSCRIPT_SCROLL_EVENT } from "./constants"
import DropdownActions from "./DropdownActions"
import useHistory from "./History/useHistory"

const View = ({ meetingPackId, account, transcriptId, className }) => {
  const [transcriptItems, setTranscriptItems] = useState([])
  const [loaded, setLoaded] = useState(false)
  const { data } = useQuery(transcriptQuery, {
    variables: { transcriptId }
  })
  const utterancesListRef = useRef()

  const [scrollToAgendaItemId, setScrollToAgendaItemId] = useState()
  const [agendaItemsAbove, setAgendaItemsAbove] = useState([])
  const [closedToTopAgendaItems, setClosedToTopAgendaItems] = useState([])
  const [scrollToUtteranceUid, setScrollToUtteranceUid] = useState()
  const [flashUtteranceUids, setFlashUtteranceUids] = useState([])

  useEffect(() => {
    const transcriptUtterances = data?.meetingPackTranscriptUtterances
    const transcriptAgendaItems = data?.meetingPackAssignedTranscriptAgendaItems

    if (transcriptUtterances && transcriptAgendaItems) {
      const result = [...transcriptUtterances]
      transcriptAgendaItems.forEach((agendaItem, i) => {
        result.splice(agendaItem.utterancesListIndex + i, 0, agendaItem)
      })

      setTranscriptItems(result)
      setLoaded(true)
    }
  }, [data])

  const scrollTo = (event) => {
    const utteranceUids = event.detail.source
    const firstExistentUtteranceUids = utteranceUids.find(
      (uid) => !!transcriptItems.find((item) => item.uid === uid)
    )

    if (!firstExistentUtteranceUids) return

    setScrollToUtteranceUid(firstExistentUtteranceUids)

    setFlashUtteranceUids(utteranceUids)
    setTimeout(() => {
      setFlashUtteranceUids([])
      setScrollToUtteranceUid(null)
    }, 2000)
  }

  useEffect(() => {
    window.addEventListener(TRANSCRIPT_SCROLL_EVENT, scrollTo)

    return () => window.removeEventListener(TRANSCRIPT_SCROLL_EVENT, scrollTo)
  }, [transcriptItems])

  const { saveToHistory, undoDisabled, redoDisabled, handleUndo, handleRedo } =
    useHistory({ transcriptId, transcriptItems, loaded })

  const { handleSave, restoreState, handleSaveUtterances, handleAssignAgendaItem } =
    useUpdateTranscript({
      transcriptId,
      transcriptItems,
      setTranscriptItems,
      saveToHistory
    })

  const scrollContextValue = useMemo(
    () => ({
      scrollToAgendaItemId,
      setScrollToAgendaItemId,
      agendaItemsAbove,
      setAgendaItemsAbove,
      closedToTopAgendaItems,
      setClosedToTopAgendaItems,
      scrollToUtteranceUid,
      flashUtteranceUids,
      utterancesListElement: utterancesListRef.current
    }),
    [
      scrollToAgendaItemId,
      agendaItemsAbove,
      closedToTopAgendaItems,
      scrollToUtteranceUid,
      flashUtteranceUids,
      utterancesListRef.current
    ]
  )

  return (
    <>
      <Header
        meetingPackId={meetingPackId}
        account={account}
        transcriptId={transcriptId}
        onUndo={handleUndo(restoreState)}
        onRedo={handleRedo(restoreState)}
        undoDisabled={undoDisabled}
        redoDisabled={redoDisabled}
        SubHeaderActionsComponent={DropdownActions}
      />

      <ScrollContext.Provider value={scrollContextValue}>
        <div
          className={classNames(
            "container h-100",
            className,
            account.hasVerseSettings ? "my-4" : "mb-4"
          )}
        >
          <div className="d-flex h-100">
            <DndProvider backend={HTML5Backend}>
              <div className="transcript-container mr-2">
                <h6 className="my-3">Transcript</h6>

                {!!agendaItemsAbove.length && !closedToTopAgendaItems.length && (
                  <FixedAgendaItem
                    agendaItem={agendaItemsAbove[agendaItemsAbove.length - 1]}
                  />
                )}

                <div ref={utterancesListRef} className="fluid-container pr-3">
                  {loaded && !transcriptItems.length && <NoContent />}

                  <Utterances
                    transcriptId={transcriptId}
                    transcriptItems={transcriptItems}
                    setTranscriptItems={setTranscriptItems}
                    handleSaveUtterances={handleSaveUtterances}
                    handleAssignAgendaItem={handleAssignAgendaItem}
                    handleSave={handleSave}
                  />
                </div>
              </div>

              <div className="agenda-items-container">
                <h6 className="my-3">Agenda items</h6>

                <AgendaItemsSidebar
                  transcriptItems={transcriptItems}
                  setTranscriptItems={setTranscriptItems}
                  handleSave={handleSave}
                />
              </div>
            </DndProvider>
          </div>
        </div>
      </ScrollContext.Provider>
    </>
  )
}

View.propTypes = {
  transcriptId: number.isRequired,
  meetingPackId: number.isRequired,
  account: shape({
    name: string.isRequired,
    hasVerseSettings: bool.isRequired
  })
}

export default styled(View)`
  max-width: ${BREAKPOINTS.xLarge};

  .transcript-container {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    position: relative;

    .fluid-container {
      scroll-behavior: smooth;
    }

    & > h6 {
      margin-left: 85px;
    }
  }

  .agenda-items-container {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
  }
`
