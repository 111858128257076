import React, { useCallback, useState, useRef } from "react"
import { bool, func, string } from "prop-types"

import { KEY_CODES } from "src/constants"
import { goTo } from "src/helpers/url"
import Icon from "src/styles/components/Icon"
import SuggestionsPanel from "src/features/Search/SuggestionsPanel"
import useCmdKForSearch from "src/hooks/useCmdKForSearch"
import useInputFocus from "src/hooks/forms/useInputFocus"

import useSuggestions from "./useSuggestions"
import { ClearSearch, InputContainer } from "./styles"

const SearchInput = ({ searchQuery, setSearchQuery, autoFocus }) => {
  const inputRef = useRef()
  const [query, setQuery] = useState(searchQuery)
  const setQueryOnChange = (e) => setQuery(e.target.value)
  const { isInputFocused, setIsFocused, setIsBlurred } = useInputFocus(false)
  const { suggestions, isSearchActive, activeSuggestion, handleKeyboardNavigation } =
    useSuggestions(query)

  const handleSubmit = useCallback(() => {
    setSearchQuery(query)
  }, [query])

  const handleEnter = useCallback(
    (e) => {
      if (e.charCode !== KEY_CODES.enter) return

      inputRef.current.blur()

      if (activeSuggestion) {
        e.preventDefault()
        goTo(activeSuggestion.path)
      } else {
        handleSubmit()
      }
    },
    [inputRef.current, handleSubmit, activeSuggestion?.path]
  )

  const handleClear = () => {
    setQuery("")
    setSearchQuery("")
  }

  const openSearchInput = () => {
    inputRef.current.focus()
  }

  useCmdKForSearch(openSearchInput)

  return (
    <InputContainer>
      <input
        ref={inputRef}
        autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
        autoComplete="off"
        type="search"
        name="search[query]"
        className="form-field"
        value={query || ""}
        placeholder="Type in text to search..."
        onChange={setQueryOnChange}
        onFocus={setIsFocused}
        onBlur={setIsBlurred}
        onKeyPress={handleEnter}
        onKeyDown={handleKeyboardNavigation}
        data-testid="search-input"
      />

      <Icon type="search" />

      <ClearSearch onClick={handleClear} data-testid="clear-search-input">
        <Icon type="cancel" />
      </ClearSearch>

      <SuggestionsPanel
        isSearchActive={isSearchActive}
        suggestions={suggestions}
        searchText={query}
        showPanel={isInputFocused}
        onSubmit={handleSubmit}
      />
    </InputContainer>
  )
}

SearchInput.propTypes = {
  setSearchQuery: func.isRequired,
  searchQuery: string,
  autoFocus: bool
}

export default SearchInput
