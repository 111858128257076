import { styled } from "@linaria/react"

export const Link = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  color: #9647d7;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;

  &:hover {
    color: #9647d7;
    text-decoration: underline;
  }

  &.deleted {
    cursor: not-allowed;
  }

  &.deleted:hover {
    text-decoration: none;
  }
`
