import React, { useState } from "react"
import { number } from "prop-types"
import {
  autoUpdate,
  flip,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react"
import { cx } from "@linaria/core"

import { FocusableItemType } from "src/features/UniversalAi/constants"
import { useUniversalAi } from "src/features/UniversalAi"
import magicWand from "images/ai/magicWand.svg"

import {
  AiIcon,
  DropdownIcon,
  DropdownMenu,
  DropdownMenuContent,
  MagicWandIcon,
  QueryDropdownItem,
  SummariseDropdownItem,
  Toggle
} from "./styles"

const MessageAiActions = ({ messageId }) => {
  const [isOpened, setIsOpen] = useState(false)
  const { summariseAsTextInSidebar, queryInSidebar } = useUniversalAi()

  const { refs, floatingStyles, context } = useFloating({
    open: isOpened,
    onOpenChange: setIsOpen,
    placement: "bottom-end",
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift()]
  })

  const dismiss = useDismiss(context)
  const click = useClick(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  const onSummarise = () => {
    setIsOpen(false)
    summariseAsTextInSidebar({
      id: messageId,
      type: FocusableItemType.Message
    })
  }

  const onQuery = () => {
    setIsOpen(false)
    queryInSidebar({ id: messageId, type: FocusableItemType.Message })
  }

  return (
    <>
      <Toggle ref={refs.setReference} {...getReferenceProps()}>
        <MagicWandIcon src={magicWand} alt="Icon" />
        <DropdownIcon type="dropdown-arrow" className={cx(isOpened && "opened")} />
      </Toggle>
      {isOpened && (
        <DropdownMenu
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <DropdownMenuContent>
            <SummariseDropdownItem onClick={onSummarise}>
              <AiIcon type="ai-summarise" />
              Summarise message
            </SummariseDropdownItem>
            <QueryDropdownItem onClick={onQuery}>
              <AiIcon type="ai-query" />
              Query message
            </QueryDropdownItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  )
}

MessageAiActions.propTypes = {
  messageId: number.isRequired
}

export default MessageAiActions
