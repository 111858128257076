import React, { useContext } from "react"
import { arrayOf, number, shape } from "prop-types"
import PerfectScrollbar from "react-perfect-scrollbar"

import {
  SuggestionsContainer,
  SuggestionsTitle
} from "src/features/Sidebar/Suggestions/styles"
import SidebarContext from "src/features/Sidebar/SidebarContext"
import { getCurrentThread } from "src/helpers/document"

import Thread from "./Thread"
import { addBadgeToTheThread, isCurrentThread } from "./helpers"

const RecentThreads = ({ recentThreads }) => {
  const { stats } = useContext(SidebarContext)
  const currentThread = getCurrentThread()
  const threads = recentThreads.map((thread) => addBadgeToTheThread(thread, stats))

  return (
    <SuggestionsContainer className="sidebar-menu">
      <PerfectScrollbar>
        <SuggestionsTitle>Recent</SuggestionsTitle>
        {threads.map((thread) => (
          <Thread
            thread={thread}
            key={thread.path}
            current={isCurrentThread(thread, currentThread)}
          />
        ))}
      </PerfectScrollbar>
    </SuggestionsContainer>
  )
}

RecentThreads.propTypes = {
  recentThreads: arrayOf(
    shape({
      id: number.isRequired
    })
  )
}

export default RecentThreads
