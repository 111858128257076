import React from "react"

import { StyledQuote } from "./styles"

const Quote = ({ children }) => {
  return (
    <>
      <StyledQuote className="quote">&quot;</StyledQuote>
      {children}
      <StyledQuote className="quote">&quot;</StyledQuote>
    </>
  )
}

export default Quote
