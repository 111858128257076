import React from "react"
import { styled } from "@linaria/react"

import starsIcon from "images/stars.svg"

const BetaLabel = ({ className }) => {
  return (
    <span className={className}>
      <img src={starsIcon} alt="Beta" className="mr-1" />
      Beta
    </span>
  )
}

export default styled(BetaLabel)`
  font-size: 12px;
  color: #181023;
  background-color: #f8f8fe;
  border: 1px #afbcce solid;
  border-radius: 4px;
  text-transform: uppercase;
  display: flex;
  padding: 2px 7px;
  margin-left: 10px;
`
