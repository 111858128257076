import { styled } from "@linaria/react"

import {
  LABEL_FONT_SIZE,
  LABEL_FONT_WEIGHT,
  LABEL_LINE_HEIGHT,
  PADDING
} from "src/features/UniversalAi/Sidebar/AreaOfFocus/styles"

export const Title = styled.div`
  padding: ${PADDING};
  padding-bottom: 5px;
  font-size: ${LABEL_FONT_SIZE};
  font-weight: ${LABEL_FONT_WEIGHT};
  line-height: ${LABEL_LINE_HEIGHT};
`
