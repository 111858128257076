import React from "react"
import { cx } from "@linaria/core"
import { func, bool, string } from "prop-types"

import ExpandableContent from "src/components/ExpandableContent"

import { Container, ContentWrapper, Toggle, ToggleIcon } from "./styles"

const ExpandableList = ({ toggle, opened, label, children }) => {
  return (
    <Container>
      <Toggle onClick={toggle}>
        <span>{label}</span>
        <ToggleIcon type="dropdown-arrow" className={cx(opened && "opened")} />
      </Toggle>

      <ExpandableContent expanded={opened}>
        <ContentWrapper>{children}</ContentWrapper>
      </ExpandableContent>
    </Container>
  )
}

ExpandableList.propTypes = {
  toggle: func.isRequired,
  opened: bool.isRequired,
  label: string.isRequired
}

export default ExpandableList
