import useAiAskQuestion from "src/hooks/ai/useAskQuestion"

import { STREAMING_START_DELAY } from "../constants"

import useSearch from "./useSearch"
import useDefineQueryType from "./useDefineQueryType"
import { QueryTypes } from "./constants"

const useSubmitQuery = ({
  focusedItem,
  onSubmit,
  onQueryTypeChange,
  onChange,
  onComplete,
  onSourcesComplete,
  onSearchResultsComplete
}) => {
  const defineQueryType = useDefineQueryType()

  const search = useSearch({
    focusedItem,
    onComplete: onSearchResultsComplete
  })

  const { askQuestion, stopStreaming } = useAiAskQuestion({
    objectId: focusedItem?.id,
    objectType: focusedItem?.type,
    onChange,
    onComplete,
    onSourcesComplete,
    startDelay: STREAMING_START_DELAY
  })

  const submitQuery = async (query) => {
    onSubmit()

    const type = await defineQueryType(query)
    onQueryTypeChange(type)

    if (type === QueryTypes.Keyword) {
      search(query)
    } else if (type === QueryTypes.Query) {
      askQuestion(query)
    }
  }

  return {
    submitQuery,
    stopStreaming
  }
}

export default useSubmitQuery
