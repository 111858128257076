import React from "react"
import { Button as BootstrapButton } from "react-bootstrap"
import classNames from "classnames"

import { buttonClass } from "./styles"

const Button = React.forwardRef((props, ref) => {
  const { className, ...otherProps } = props

  return (
    <BootstrapButton
      variant="outline-danger"
      className={classNames(className, buttonClass)}
      color="danger"
      size="sm"
      ref={ref}
      {...otherProps}
    />
  )
})

export default Button
