import { styled } from "@linaria/react"

import {
  navBackgroundColor,
  borderColor,
  linkColor,
  titleColor,
  activeBackgroundColor,
  hoverBackgroundColor
} from "src/features/Sidebar/styles"
import Icon from "src/styles/components/Icon"

export const Container = styled.div`
  position: relative;
  align-self: stretch;
  padding: 10px 12px;
`

export const SuggestionInputGroup = styled.div`
  border-radius: 8px;
  border: 1px solid ${borderColor};
  background: ${navBackgroundColor};
  padding: 0 12px;
  cursor: pointer;

  ${Icon} {
    color: ${titleColor};
  }

  input {
    background: ${navBackgroundColor};
    border: none;
    border-radius: 8px;
    color: ${linkColor};
    padding: 8px 0;
    cursor: pointer;

    &::placeholder,
    &::-ms-input-placeholder {
      color: rgba(231, 231, 241, 0.7);

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &:focus {
      color: white;
      box-shadow: none;
      background: ${activeBackgroundColor};
    }
  }

  &.focused {
    background: ${activeBackgroundColor};

    input {
      background: ${activeBackgroundColor};
    }

    ${Icon} {
      color: white;
    }
  }
`

export const SuggestionsContainer = styled.div`
  position: absolute;
  left: 12px;
  right: 12px;
  top: 60px;
  max-height: 404px; // 380px (10 items) + 24 padding

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 8px;
  background: ${activeBackgroundColor};
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 12px 0;
  z-index: 10;
    
  > div { // PerfectScrollbar
    width: 100%;
  }
}  
`

export const SuggestionsTitle = styled.div`
  color: ${linkColor};

  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;

  padding: 6px 12px;
  margin-bottom: 8px;
`

export const SuggestionItem = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  padding: 6px 12px;
  margin-bottom: 4px;

  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &:hover,
  &.active {
    background: ${hoverBackgroundColor};
    color: white;
  }

  &.unread,
  &.mention,
  &.pending {
    padding-right: 25px;
  }
`
