import React, { useState, useEffect } from "react"
import { string, bool, number } from "prop-types"
import classNames from "classnames"

import KnowaLogoLoader from "src/styles/components/KnowaLogoLoader"
import useCurrentEntity from "src/hooks/useCurrentEntity"

const IframeApp = ({
  path,
  groupName,
  appName,
  appContentId,
  appContentPresent,
  wrapperClass = ""
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const hideLoader = () => setIsLoading(false)
  const appTitle = `Knowa - ${groupName} > ${appName}`

  const { setCurrentEntity, clearCurrentEntity } = useCurrentEntity()

  useEffect(() => {
    if (!appContentPresent) return

    setCurrentEntity({ id: appContentId, type: "apps_content" })

    return () => clearCurrentEntity()
  }, [appContentId, appContentPresent])

  return (
    <div className={`h-100 d-flex fluid-container ${wrapperClass}`}>
      {isLoading && <KnowaLogoLoader />}
      <iframe
        title={appTitle}
        className={classNames("w-100 h-100 border-0", {
          "d-none": isLoading
        })}
        src={path}
        onLoad={hideLoader}
      />
      <div className="iframe-footer" />
    </div>
  )
}

IframeApp.propTypes = {
  path: string.isRequired,
  groupName: string.isRequired,
  appName: string.isRequired,
  appContentId: number.isRequired,
  appContentPresent: bool.isRequired,
  wrapperClass: string
}

export default IframeApp
