import { styled } from "@linaria/react"

import FileIcon from "src/styles/components/FileIcon"

export const Link = styled.span`
  font-size: 12px;
  color: #4f4f58;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: #53a9f5;
  }

  &.deleted {
    cursor: not-allowed;
  }

  &.deleted:hover {
    text-decoration: none;
    color: #4f4f58;
  }
`

export const Icon = styled(FileIcon)`
  min-height: 20px;
  min-width: 20px;
  height: 20px;
  width: 20px;
  margin-bottom: 0;
  margin-right: 0;
  background-size: cover;
`
