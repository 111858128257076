import React, { useState } from "react"
import {
  useFloating,
  autoUpdate,
  useHover,
  useInteractions,
  flip,
  shift,
  useClick,
  useDismiss
} from "@floating-ui/react"
import { oneOf } from "prop-types"

import { Source as SourceType } from "src/features/UniversalAi/Sidebar/propTypes"
import SourcePreview from "src/features/UniversalAi/Sidebar/ThreadItem/Answer/SourcePreview"
import { FocusableItemType } from "src/features/UniversalAi/constants"
import { possessive } from "src/helpers/string"
import { formatDate } from "src/helpers/datetime"
import { buildTitleWithDeletedLabel } from "src/features/UniversalAi/Sidebar/helpers"
import useMobileScreen from "src/hooks/useMobileScreen"
import useUniversalAi from "src/features/UniversalAi/useUniversalAi"

import { LinkTitleMode, LinkTitleModes } from "../constants"

import { Link } from "./styles"

const buildMessageTitle = (source) => {
  const formattedCreatedAt = formatDate(source.createdAt, "Do MMM, YYYY")

  return `${possessive(source.author)} message, ${formattedCreatedAt}`
}

const buildTitle = (source, mode) => {
  const isMessage = source.sourceType === FocusableItemType.Message

  if (isMessage) {
    return buildMessageTitle(source)
  }

  const pageLabel = `Page ${source.page}`
  const titleWithPage = source.page ? `${pageLabel} - ${source.title}` : source.title

  if (mode === LinkTitleMode.Title) {
    return titleWithPage
  }

  if (mode === LinkTitleMode.PageNumber) {
    return pageLabel
  }
}

const Source = ({ source, linkTitleMode }) => {
  const isMobile = useMobileScreen()
  const { close: closeSidebar } = useUniversalAi()
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip(), shift()]
  })

  const hover = useHover(context, { move: false })
  const click = useClick(context, { enabled: isMobile })
  const dismiss = useDismiss(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, click, dismiss])

  const trackSourceClick = (clickTarget) => {
    window.analytics.track("KnowaQ_Visit-Source", {
      source_id: source.sourceId,
      source_type: source.sourceType,
      link: source.link,
      click_target: clickTarget
    })
  }

  const navigateToSource = (onNavigate) => {
    if (source.sourceDeleted) return
    if (onNavigate) onNavigate()

    window.Turbo.visit(source.link)
    if (isMobile) closeSidebar()
  }

  const handleLinkClick = () => {
    if (isMobile) return

    navigateToSource(() => trackSourceClick("sources_list"))
  }

  const handleSourcePreviewNavigate = () => {
    navigateToSource(() => trackSourceClick("preview_title"))
  }

  return (
    <>
      <Link
        onClick={handleLinkClick}
        ref={refs.setReference}
        {...getReferenceProps()}
        className={source.sourceDeleted ? "deleted" : null}
      >
        {buildTitleWithDeletedLabel(
          buildTitle(source, linkTitleMode),
          source.sourceDeleted
        )}
      </Link>

      {isOpen && (
        <SourcePreview
          source={source}
          ref={refs.setFloating}
          style={floatingStyles}
          onNavigate={handleSourcePreviewNavigate}
          {...getFloatingProps()}
        />
      )}
    </>
  )
}

Source.propTypes = {
  source: SourceType.isRequired,
  linkTitleMode: oneOf(LinkTitleModes).isRequired
}

export default Source
