import { useMutation } from "@apollo/client"
import { useCallback } from "react"

import { CHANNELS } from "src/constants/ably"
import useAblyChannel from "src/hooks/useAblyChannel"
import { getCurrentUserId } from "src/helpers/user"
import { useUniversalAiSelector } from "src/features/UniversalAi/store/"

import useChannelName from "../useChannelName"

import performSearchMutation from "./performSearchMutation.gql"

const useSearch = ({ focusedItem, onComplete } = {}) => {
  const channelName = useChannelName("search", CHANNELS.search.results)
  const { searchRequestId } = useUniversalAiSelector()

  const [mutate] = useMutation(performSearchMutation)

  const search = (query) => {
    return mutate({
      variables: {
        query,
        channelName,
        searchRequestId,
        where: [{ id: focusedItem.id, type: focusedItem.type }]
      }
    })
  }

  const onAblyMessage = useCallback(
    ({ data: publisherData }) => {
      if (
        publisherData.userId === getCurrentUserId() &&
        publisherData.searchRequestId === searchRequestId
      ) {
        if (onComplete) onComplete(publisherData.results)
      }
    },
    [searchRequestId]
  )

  useAblyChannel(channelName, { onMessage: onAblyMessage })

  return search
}

export default useSearch
