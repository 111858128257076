import React from "react"
import { bool, string } from "prop-types"
import { styled } from "@linaria/react"
import classNames from "classnames"

import Icon from "src/styles/components/Icon"
import { navBackgroundColor } from "src/features/Sidebar/styles"

const BaseNavIcon = ({ type, unread, unreadAndMention, className }) => {
  return (
    <div
      className={classNames(className, {
        unread: unread || unreadAndMention,
        mention: unreadAndMention
      })}
    >
      <div>
        <Icon type={type} />
      </div>
    </div>
  )
}

BaseNavIcon.propTypes = {
  type: string.isRequired,
  unread: bool,
  unreadAndMention: bool
}

export default styled(BaseNavIcon)`
  display: flex;
  padding: 0 13px;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: ${navBackgroundColor};

  > div {
    display: flex;
    height: 36px;
    padding: 8px;

    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  ${Icon} {
    font-size: 16px;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  &.unread::before,
  &.mention::before {
    left: 95%;
    bottom: 95%;
  }
`
