import { styled } from "@linaria/react"

import { Body, Header } from "src/styles/components/Modal"

export const ModalHeader = styled(Header)`
  padding: 0.5rem 1rem;
`
export const ModalBody = styled(Body)`
  padding: 0;
`
