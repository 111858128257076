import React from "react"

import { ThreadItem as ThreadItemType } from "src/features/UniversalAi/Sidebar/propTypes"

import ExpandableList from "../ExpandableList"

import useOpenSearchResults from "./useOpenSearchResults"
import { Container } from "./styles"
import ResultItem from "./ResultItem"

const SearchResults = ({ threadItem }) => {
  const { opened: searchResultsOpened, toggle: toggleSearchResults } =
    useOpenSearchResults(threadItem)

  const { query, searchResults = [] } = threadItem

  const toggleLabel = searchResults.length === 1 ? "Result" : "Results"

  return (
    <div>
      <div>Results containing &quot;{query}&quot;:</div>
      <ExpandableList
        toggle={toggleSearchResults}
        opened={searchResultsOpened}
        label={`${searchResults.length} ${toggleLabel}`}
      >
        <Container>
          {searchResults.length === 0 && <div>No results</div>}
          {searchResults.map((resultItem) => (
            <ResultItem
              key={`${resultItem.type}-${resultItem.id}`}
              resultItem={resultItem}
            />
          ))}
        </Container>
      </ExpandableList>
    </div>
  )
}

SearchResults.propTypes = {
  threadItem: ThreadItemType.isRequired
}

export default SearchResults
