import { useDispatch } from "react-redux"

import {
  goBack as goBackAction,
  goForward as goForwardAction
} from "src/features/meetingPacks/MeetingPack/meetingPackSlice"

const useNavigation = () => {
  const dispatch = useDispatch()

  const goBack = () => dispatch(goBackAction())
  const goForward = () => dispatch(goForwardAction())

  return { goBack, goForward }
}

export default useNavigation
