import { styled } from "@linaria/react"

import { linkActiveColor, linkHoverColor, TEXT_COLORS } from "src/styles/colors"
import { withVariations } from "src/helpers/linaria"

export default styled(withVariations("button", "noFocus"))`
  background: transparent;
  border: none;
  display: inline;
  color: ${TEXT_COLORS.link};
  padding: 0;
  margin-left: 0;

  &:hover {
    color: ${linkHoverColor};
  }

  &:focus {
    color: ${linkHoverColor};
  }

  &:active {
    color: ${linkActiveColor};
    border: none;
  }

  &--no-focus {
    &:focus {
      outline: none;
    }
  }
`
