import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store"

const useOpenSources = (threadItem) => {
  const { openedSources } = useUniversalAiSelector()
  const dispatch = useDispatch()

  const opened =
    openedSources[threadItem.id] === undefined
      ? !!threadItem.current
      : !!openedSources[threadItem.id]

  const open = () => {
    dispatch(actions.openSources(threadItem.id))
  }

  const close = () => {
    dispatch(actions.closeSources(threadItem.id))
  }

  const toggle = () => (opened ? close() : open())

  return { opened, open, close, toggle }
}

export default useOpenSources
