import React, { useContext } from "react"
import classNames from "classnames"
import { arrayOf, string } from "prop-types"

import HeaderContext from "src/features/Header/HeaderContext"
import { isOnThePage } from "src/helpers/url"
import { getCurrentUser } from "src/helpers/user"
import {
  getPendingActionsCount,
  getUnreadChatsCount,
  getUnreadMentionsCount
} from "src/features/unreadStats/helpers"

import SearchNavIcon from "./SearchNavIcon"

const HeaderNav = ({ exitPath, whereIds }) => {
  const stats = useContext(HeaderContext)

  const currentUser = getCurrentUser()
  const isOnTheHomePage = isOnThePage("home")
  const isOnTheSearchPage = isOnThePage("search")

  const hasMeetings = currentUser.meeting_packs.length
  const hasStarredItems = currentUser.starred_items.length

  const unreadChatsCount = getUnreadChatsCount(stats)
  const unreadMentionsCount = getUnreadMentionsCount(stats)
  const pendingActionsCount = getPendingActionsCount(stats)
  const unreadHome = unreadMentionsCount || pendingActionsCount

  return (
    <div className="header-nav">
      <div className="header-nav-section">
        <a
          href={hasMeetings ? "/meetings" : "/mentions"}
          className={classNames("header-nav-item saved-offline hidden-on-large-screens", {
            highlighted: isOnTheHomePage,
            unread: unreadChatsCount,
            "unread mention": unreadHome
          })}
          data-exit-path={exitPath}
          data-testid="home-navbar-link"
          aria-label={hasMeetings ? "Meetings" : "Mentions"}
        >
          <span className={hasStarredItems ? "icon-home-filled" : "icon-home-hollow"} />
        </a>

        {!isOnTheSearchPage && <SearchNavIcon whereIds={whereIds} />}
      </div>
    </div>
  )
}

HeaderNav.propTypes = {
  exitPath: string,
  whereIds: arrayOf(string)
}

export default HeaderNav
