import React from "react"
import { Dropdown } from "react-bootstrap"
import { func } from "prop-types"

import DropdownItem from "./DropdownItem"
import Button from "./Button"

const CloseActionButton = ({ onSelect }) => {
  return (
    <Dropdown align="end" onSelect={onSelect}>
      <Dropdown.Toggle as={Button}>Signing options</Dropdown.Toggle>

      <Dropdown.Menu align="end" alignRight>
        <DropdownItem eventKey="now" text="Close immediately" />
      </Dropdown.Menu>
    </Dropdown>
  )
}

CloseActionButton.propTypes = {
  onSelect: func.isRequired
}

export default CloseActionButton
