import React, { forwardRef, useLayoutEffect } from "react"
import { throttle } from "lodash"
import { useDispatch } from "react-redux"

import { actions, useUniversalAiSelector } from "src/features/UniversalAi/store/"

import { Container, ScrollableContainer, ShadowOverlay } from "./styles"

const ThreadItemsContainer = forwardRef(({ children }, ref) => {
  const { scrollPosition } = useUniversalAiSelector()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    const node = ref.current
    if (!node) return

    // eslint-disable-next-line no-param-reassign
    node.scrollTop = scrollPosition || 0

    const handleScroll = throttle(
      () => {
        const endReached = node.scrollTop + node.clientHeight >= node.scrollHeight
        // To prevent confusing behavior when Actions block expands, collapses at the end of the scroll
        if (endReached) return

        dispatch(actions.saveScrollPosition(node.scrollTop))
      },
      100,
      { leading: true, trailing: true }
    )

    // We use "wheel" and "touchmove" events instead of just "scroll" because this
    // component has a lot of expandable/collapsible components and when user expands
    // or collapses something then "scroll" event is also triggered and it causes
    // visual glitches and confusing behavior. We want this component to update
    // scrollPosition only when user deliberately updates scroll position.
    node.addEventListener("wheel", handleScroll)
    node.addEventListener("touchmove", handleScroll)

    return () => {
      handleScroll.cancel() // .cancel comes from "throttle" lodash func
      node.removeEventListener("wheel", handleScroll)
      node.addEventListener("touchmove", handleScroll)
    }
  }, [!!ref.current])

  const isScrolled = scrollPosition > 0

  return (
    <Container>
      <ShadowOverlay className={isScrolled ? "visible" : ""} />

      <ScrollableContainer ref={ref}>{children}</ScrollableContainer>
    </Container>
  )
})

ThreadItemsContainer.displayName = "ThreadItemsContainer"

export default ThreadItemsContainer
