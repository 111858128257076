import { styled } from "@linaria/react"

import { BACKGROUND_COLORS, BASE_COLORS } from "src/styles/colors"
import Icon from "src/styles/components/Icon"
import Link from "src/styles/components/Button/Link"
import { SPACES, FONT_SIZES } from "src/styles/sizes"
import { withVariations } from "src/helpers/linaria"

export const Actions = styled.div`
  color: ${BASE_COLORS.darkGray};

  .dropdown-menu {
    padding: 0;
  }
`

export const DropdownItem = styled(withVariations(Link, "variant"))`
  padding: ${SPACES.xSmall} ${SPACES.small};
  white-space: nowrap;
  color: ${BASE_COLORS.darkViolet};

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    background-color: ${BACKGROUND_COLORS.selected};
  }

  &--variant-danger {
    color: ${BASE_COLORS.red};

    &:active,
    &:focus,
    &:hover {
      color: ${BASE_COLORS.red};
    }
  }

  ${Icon} {
    font-size: ${FONT_SIZES.small};
    vertical-align: baseline;
    margin-left: 0.25rem;
  }
`
