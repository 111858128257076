import { styled } from "@linaria/react"
import { Modal, Image } from "react-bootstrap"

import { SPACES } from "src/styles/sizes"

export const ModalFooter = styled(Modal.Footer)`
  justify-content: center;
`

export const SuccessImage = styled(Image)`
  margin: ${SPACES.small} 0;
`

export const TextContainer = styled.div`
  margin-top: ${SPACES.small};
`
