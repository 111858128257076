import React from "react"
import { styled } from "@linaria/react"
import { func, string } from "prop-types"

import DateContainer from "./DateContainer"

const DatePickers = ({ dateFrom, setDateFrom, dateTo, setDateTo, className }) => {
  return (
    <div className={className}>
      <DateContainer value={dateFrom} setValue={setDateFrom} placeholder="From date" />
      <DateContainer value={dateTo} setValue={setDateTo} placeholder="To date" />
    </div>
  )
}

DatePickers.propTypes = {
  className: string.isRequired,
  dateFrom: string,
  setDateFrom: func.isRequired,
  dateTo: string,
  setDateTo: func.isRequired
}

export default styled(DatePickers)`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`
