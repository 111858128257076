import React, { useEffect, useRef } from "react"
import { bool, func, string } from "prop-types"

import { KEY_CODES } from "src/constants"

import useIsSidebarScrolled from "../useIsSidebarScrolled"

import {
  BorderContainer,
  Icon,
  Button,
  Textarea,
  InputContainer,
  TitleContainer
} from "./styles"

const QueryInput = ({
  query,
  title,
  inputDisabled,
  inProgress,
  onChange,
  onSubmit,
  onStop
}) => {
  const textareaRef = useRef()
  const isScrolled = useIsSidebarScrolled()

  useEffect(() => {
    if (!textareaRef.current || inputDisabled) return

    textareaRef.current.focus()
  }, [!!textareaRef.current, inputDisabled]) // can't focus input when it's disabled

  // Auto-expand textarea to fit content
  useEffect(() => {
    // Reset height when content is deleted
    textareaRef.current.style.height = 0
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
  }, [query])

  const isQueryBlank = !query || !query.trim()

  const handleQueryChange = (e) => {
    onChange(e.currentTarget.value)
  }

  const handleKeyDown = (e) => {
    // shift+enter for a new line, enter to submit
    if (e.keyCode !== KEY_CODES.enter || e.shiftKey === true) return

    e.preventDefault()

    if (!isQueryBlank) onSubmit()
  }

  return (
    <BorderContainer className={isScrolled ? "compact" : ""}>
      <TitleContainer>Query {title}</TitleContainer>
      <InputContainer>
        <Textarea
          ref={textareaRef}
          value={query}
          placeholder="Query or search for anything"
          disabled={inputDisabled}
          onChange={handleQueryChange}
          onKeyDown={handleKeyDown}
        />
        {inProgress ? (
          <Button onClick={onStop}>
            <Icon className="small">&#9632;</Icon>
          </Button>
        ) : (
          <Button disabled={isQueryBlank || inputDisabled} onClick={onSubmit}>
            <Icon>&uarr;</Icon>
          </Button>
        )}
      </InputContainer>
    </BorderContainer>
  )
}

QueryInput.propTypes = {
  query: string,
  title: string,
  inputDisabled: bool,
  inProgress: bool,
  onSubmit: func.isRequired,
  onStop: func.isRequired,
  onChange: func.isRequired
}

export default QueryInput
