import { styled } from "@linaria/react"

import Icon from "src/styles/components/Icon"

export const Banner = styled.div`
  color: white;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  background-color: #181023;
  padding: 12px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
`

export const ClockIcon = styled(Icon)`
  font-size: 16px;
`
