import { styled } from "@linaria/react"

import UnstyledButton from "src/components/UnstyledButton"
import { BASE_TEXT_COLOR } from "src/features/UniversalAi/Sidebar/styles"
import Icon from "src/styles/components/Icon"

export const Container = styled.div`
  padding-top: 10px;

  h6:first-of-type {
    display: none;
  }

  &.in-progress sup {
    display: none;
  }

  & sup {
    position: relative;
    top: -0.5em;
    background-color: #e9e9ea;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 4px;
    color: ${BASE_TEXT_COLOR};
    font-size: 11px;
    font-weight: 700;
    cursor: pointer;
  }
`

export const CopyResponseContainer = styled.div`
  margin-top: 16px;
`

export const CopyResponseButton = styled(UnstyledButton)`
  background-color: #f4f4f5;
  font-weight: 500;
  color: ${BASE_TEXT_COLOR};
  padding: 5px 12px 5px 12px;
  border-radius: 200px;

  &:hover {
    background-color: #f4f4f5;
  }
`

export const CopyIcon = styled(Icon)`
  margin-right: 5px;
`
