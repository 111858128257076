import React, { useEffect, useRef } from "react"
import { Dropdown } from "react-bootstrap"
import { bool, func, shape, string, number } from "prop-types"
import { cx } from "@linaria/core"

import DropdownToggle from "src/features/Sidebar/Accounts/Account/DropdownToggle"
import Icon from "src/styles/components/Icon"

import { Button, Container, NameContainer, Name } from "./styles"
import StarIcon from "./StarIcon"

const AccountName = ({
  account,
  onClick,
  isAccountOpened,
  isStarAvailable,
  current,
  focusAccount,
  setFocusAccountAfterUpdate
}) => {
  const ref = useRef()

  const hasItems = account.meetingPacks.length > 0 || account.schemes.length > 0

  useEffect(() => {
    if (!focusAccount) return

    ref.current.scrollIntoView()
    setFocusAccountAfterUpdate(null)
  }, [focusAccount])

  return (
    <Container className={cx("sidebar-subsection-name", current && "current")} ref={ref}>
      <NameContainer>
        <Button
          className={cx("sidebar-organization-name", !isAccountOpened && "collapsed")}
          onClick={onClick}
        >
          {hasItems && <Icon type="dropdown-arrow" className="no-transition" />}
          <Name>{account.name}</Name>
        </Button>

        {isStarAvailable && (
          <StarIcon
            account={account}
            setFocusAccountAfterUpdate={setFocusAccountAfterUpdate}
          />
        )}
      </NameContainer>

      {account.canManage && (
        <Dropdown>
          <Dropdown.Toggle as={DropdownToggle} />
          <Dropdown.Menu popperConfig={{ placement: "bottom-start" }}>
            <Dropdown.Item href={account.managePath}>Manage Organisation</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Container>
  )
}

AccountName.propTypes = {
  account: shape({
    id: number.isRequired,
    name: string.isRequired,
    starred: bool.isRequired,
    newMeetingPath: string.isRequired,
    managePath: string.isRequired,
    canManage: bool.isRequired,
    canCreateMeetingPack: bool.isRequired
  }).isRequired,
  onClick: func.isRequired,
  isAccountOpened: bool.isRequired,
  isStarAvailable: bool.isRequired,
  current: bool.isRequired,
  focusAccount: bool,
  setFocusAccountAfterUpdate: func.isRequired
}

export default AccountName
