import React from "react"
import { arrayOf } from "prop-types"

import { SearchResultItemBreadcrumb } from "src/features/UniversalAi/Sidebar/propTypes"

import { Container } from "./styles"

const Breadcrumbs = ({ breadcrumbs }) => {
  // First breadcrumb is account and we don't want to show it
  const breadcrumbsToShow = breadcrumbs.slice(1)

  return (
    <Container>
      {breadcrumbsToShow.map((breadcrumb, index) => {
        return (
          <span key={`${breadcrumb.name}-${breadcrumb.path}`}>
            {index !== 0 && " > "}
            {breadcrumb.name}
          </span>
        )
      })}
    </Container>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: arrayOf(SearchResultItemBreadcrumb).isRequired
}

export default Breadcrumbs
