import { styled } from "@linaria/react"
import { Button } from "react-bootstrap"

import { withVariations } from "src/helpers/linaria"
import { BACKGROUND_COLORS, BORDER_COLORS } from "src/styles/colors"

export default styled(withVariations(Button, "width"))`
  &.btn {
    background-color: ${BACKGROUND_COLORS.button};
    border-color: ${BORDER_COLORS.button};
    letter-spacing: normal;
    margin: 0;

    &[disabled]:hover {
      background-color: ${BACKGROUND_COLORS.button};
      border-color: ${BORDER_COLORS.button};
    }
  }

  &--width-md {
    width: 100px;
  }
`
