import { useMutation } from "@apollo/client"

import { QueryTypes } from "../constants"

import defineQueryTypeMutation from "./defineQueryTypeMutation.gql"

const RAW_QUERY_TYPES = {
  1: QueryTypes.Keyword,
  2: QueryTypes.Query
}

const useDefineQueryType = () => {
  const [mutate] = useMutation(defineQueryTypeMutation)

  const defineQueryType = async (query) => {
    const { data } = await mutate({ variables: { query } })
    return RAW_QUERY_TYPES[data?.askAiQuestionType?.questionType]
  }

  return defineQueryType
}

export default useDefineQueryType
