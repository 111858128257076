import React from "react"
import { oneOf, string } from "prop-types"

import { Container } from "./styles"

const AlertBanner = ({ text, level }) => {
  return <Container className={level}>{text}</Container>
}

AlertBanner.propTypes = {
  text: string.isRequired,
  level: oneOf(["warning"]).isRequired
}

export default AlertBanner
