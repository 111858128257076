import { useDispatch, useSelector } from "react-redux"

import { actions } from "src/resources/currentEntity/slice"

const useCurrentEntity = () => {
  const dispatch = useDispatch()
  const currentEntity = useSelector((state) => state.currentEntity)

  const setCurrentEntity = (newCurrentEntity) => {
    dispatch(actions.setCurrentEntity(newCurrentEntity))
  }

  const clearCurrentEntity = () => dispatch(actions.clearCurrentEntity())

  return { currentEntity, setCurrentEntity, clearCurrentEntity }
}

export default useCurrentEntity
