import React, { useRef } from "react"
import { useDrag, useDrop } from "react-dnd"
import { Spinner } from "react-bootstrap"
import { bool, number, shape, string, func } from "prop-types"
import classNames from "classnames"

import dropSort from "src/helpers/dropSort"

import { FileRowContainer, FileTitle } from "./styles"

const FILE_TYPE = "file"

const SortableFileRow = ({ file, isUploading, index, setFiles }) => {
  const id = file.name
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: FILE_TYPE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      dropSort({ ref, item, index, setItems: setFiles, monitor })
    }
  })

  const [{ isDragging }, drag] = useDrag({
    type: FILE_TYPE,
    item: () => {
      return { id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  })

  drag(drop(ref))

  return (
    <FileRowContainer
      ref={ref}
      data-testid="file-row"
      data-handler-id={handlerId}
      role="button"
      className={classNames({ isDragging })}
    >
      <FileTitle>{file.name}</FileTitle>

      {isUploading && <Spinner animation="grow" size="sm" />}
    </FileRowContainer>
  )
}

SortableFileRow.propTypes = {
  file: shape({
    name: string.isRequired
  }),
  isUploading: bool,
  index: number.isRequired,
  setFiles: func
}

export default SortableFileRow
