import { css } from "@linaria/core"

import { BASE_COLORS, TEXT_COLORS } from "src/styles/colors"

export const buttonClass = css`
  &.btn {
    font-size: 1rem;
    border: 1px solid ${TEXT_COLORS.danger};

    &:focus,
    &:visited,
    &:active,
    &:hover {
      box-shadow: none !important;
      background-color: ${BASE_COLORS.white} !important;
      color: ${TEXT_COLORS.danger} !important;
      border: 1px solid ${TEXT_COLORS.danger} !important;
      font-size: 1rem !important;
    }

    &::after {
      font-size: 1rem;
    }
  }
`
