import { useCallback } from "react"
import { useApolloClient } from "@apollo/client"

import {
  FAIL_MESSAGE,
  FAIL_AUTHENTICATION_MESSAGE
} from "src/features/UniversalAi/constants"
import { CHANNELS } from "src/constants/ably"
import useAblyChannel from "src/hooks/useAblyChannel"
import { getCurrentUserId } from "src/helpers/user"
import useChannelName from "src/features/UniversalAi/Sidebar/useChannelName"

import askAiQuestionMutation from "./askAiQuestionMutation.gql"
import useStreaming from "./useStreaming"

const useAskQuestion = ({
  objectId,
  objectType,
  onChange,
  onComplete,
  onSourcesComplete,
  startDelay = 0
}) => {
  const apolloClient = useApolloClient()

  const channelName = useChannelName(
    `askQuestion-${objectType}`,
    CHANNELS.aiAnswer.askQuestion
  )

  const {
    setContent,
    setFinished,
    reset: resetStreaming,
    interrupt: stopStreaming
  } = useStreaming({
    streamId: "askQuestion",
    onChange,
    onComplete,
    channelName,
    startDelay
  })

  const handleAnswer = ({ value, complete, error }) => {
    setContent(value || error)

    if (complete || error) {
      setFinished(true)
    }
  }

  const askQuestion = (question) => {
    resetStreaming()

    apolloClient
      .mutate({
        mutation: askAiQuestionMutation,
        variables: {
          question,
          objectId,
          objectType,
          channelName
        }
      })
      .then((response) => {
        const { data } = response

        if (!data?.askAiQuestion?.success) {
          console.error("askAiQuestionMutation error", response)
          handleAnswer({ error: data?.errors || FAIL_MESSAGE })
        }
      })
      .catch((response) => {
        const error =
          response.graphQLErrors &&
          response.graphQLErrors[0]?.extensions?.code === "unauthorized"
            ? FAIL_AUTHENTICATION_MESSAGE
            : FAIL_MESSAGE

        console.error("askAiQuestionMutation error", response)
        handleAnswer({ error })
      })
  }

  const onAblyMessage = useCallback(
    ({ data }) => {
      const isTargetObject =
        data.userId === getCurrentUserId() &&
        data.objectId.toString() === objectId?.toString() &&
        data.objectType === objectType

      if (!isTargetObject) return

      if (data.answer) {
        handleAnswer({ value: data.answer, complete: data.finished })

        if (data.finished && data.success) onSourcesComplete(data.sources)
      }

      if (!data.success) {
        console.error("useAskQuestion Ably error", data)
        handleAnswer({ error: data.error || FAIL_MESSAGE })
      }
    },
    [objectId, objectType]
  )

  useAblyChannel(channelName, { onMessage: onAblyMessage })

  return { askQuestion, stopStreaming }
}

export default useAskQuestion
