import apiClient from "src/app/apiClient"

export const updateSignatories = ({ documentId }) => {
  return apiClient.put(`/api/signed_documents/${documentId}/update_signatories`)
}

export const sign = ({ documentId }) => {
  return apiClient.put(`/api/signed_documents/${documentId}/sign`)
}

export const close = ({ documentId }) => {
  return apiClient.put(`/api/signed_documents/${documentId}/close`)
}
